import { Box, FormControl, Select } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import DropDownSvg from '../../assets/icons/dropdown.svg';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import createInflection from '../../utils/inflection/createInflection';
import clsx from 'clsx';
import { useViewport } from '../../utils/useViewport';
import {
  actionSetSubmittedTickets,
  actionGetEventAvailabilityRequest,
} from '../../store/Availability/AvailabilityAction';
import { generateAvailabilityInputForSpecialEvent } from '../../utils/generateAvailabilityInput';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import Typography from '../../components/overrides/Typography';
import {
  EVENT_DROPDOWN_BUTTON_TEXT,
  EVENT_DROPDOWN_HEADER,
  EVENT_DROPDOWN_LABEL,
} from '../../assets/copy';
import DateTime from '../../utils/dateTimeHelpers';
import { trackClick } from '../../utils/useOneTag';

const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing(1),
  },
  contentSection: {
    flexWrap: 'wrap',
    display: 'flex',
    width: '100%',
  },
  content: {
    padding: theme.spacing(2, 3, 3),
    [theme.breakpoints.up('xl')]: {
      flex: '1 1 100%',
      padding: theme.spacing(2, 7, 0),
    },
  },
  buttonSection: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up('xl')]: {
      marginLeft: theme.spacing(3),
    },
  },
  buttonSectionCenter: {
    [theme.breakpoints.up('xl')]: {
      alignItems: 'center',
      display: 'flex',
    },
  },
  button: {
    minWidth: 136,
    height: '32px',
  },
  formControl: {
    minWidth: 342,
    '& .MuiOutlinedInput-input': {
      ...theme.typography.medium1Normal,
      boxSizing: 'border-box',
      height: 'fit-content',
      padding: theme.spacing(0.75, 1),
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 4,
      height: 48,
      '& fieldset': {
        borderColor: theme.palette.digitalGray[60],
      },
    },
  },
  dropDown: {
    top: `calc(40%)`,
  },
}));

const EventTicketDropdown = ({
  venue,
  buttonText = EVENT_DROPDOWN_BUTTON_TEXT,
}) => {
  const classes = useStyles();
  const { width } = useViewport();
  const dispatch = useDispatch();
  const { submittedTickets } = useSelector((state) => state.availability);
  const [selectedTickets, setSelectedTickets] = useState(submittedTickets);
  const [isDropdownOptionSelected, setIsDropdownOptionSelected] =
    useState(false);
  const guestOptions = Array.from({ length: 10 }, (_, i) => ({
    value: i + 1,
    label: i + 1,
  }));
  const currentDate = DateTime.now().startOf('day');

  const handleCheckAvailability = (event) => {
    setSelectedTickets(event.target.value);
    trackClick(event, 'event-get-tickets', 'Dropdown');
    dispatch(
      actionSetSubmittedTickets({
        submittedTickets: event.target.value,
      })
    );
    setIsDropdownOptionSelected(true);
  };

  useEffect(() => {
    if (isDropdownOptionSelected) {
      const eventDate = venue.eventDates.find(
        (date) => new DateTime(date) >= currentDate
      );

      const input = generateAvailabilityInputForSpecialEvent(
        venue,
        eventDate,
        submittedTickets
      );
      dispatch(actionGetEventAvailabilityRequest(input));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittedTickets, isDropdownOptionSelected]);

  const dropdownIcon = (selectProps) => (
    <img
      src={DropDownSvg}
      alt=""
      className={clsx(classes.dropDown, selectProps.className)}
    />
  );

  return (
    <div className={classes.content}>
      <Typography
        className={classes.header}
        variant={width > WIDTH_BREAKPOINT ? 'medium1Normal' : 'small2Normal'}
        component="h2">
        {EVENT_DROPDOWN_HEADER}
      </Typography>
      <Box className={classes.contentSection}>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            id="guestsSelector"
            native
            value={selectedTickets}
            onChange={handleCheckAvailability}
            inputProps={{ 'aria-label': 'guestLabel' }}
            IconComponent={dropdownIcon}>
            {guestOptions.map((item) => (
              <option value={item.value} key={`guests-${item.value}`}>
                {createInflection(EVENT_DROPDOWN_LABEL, item.label)}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};

export default EventTicketDropdown;
