import { put, takeLatest } from 'redux-saga/effects';

import {
  createReservation7R,
  cancelReservation7R,
} from '../../graphql/mutations';
import { updateReservation7R } from '../../graphql/customMutations';
import {
  getReservation7R,
  listReservationsByUser7R,
} from '../../graphql/queries';
import {
  callGraphqlWithToken,
  checkMaintenanceWindow,
  dispatchError,
} from '../helpers';
import * as ACTIONS from './ReservationsAction';
import * as TYPES from './ReservationsTypes';
import { ERROR_PAGE_TYPES } from '../../utils/constants/ErrorPageTypes';
import { actionSetErrorType } from '../Error/ErrorAction';
import { noticeError } from '../../utils/newRelic';

const isHandledError = (error) => {
  const errors = new Set(['DOUBLE_BOOKED', 'MAINTENANCE', 'NO_AVAILABILITY']);
  return errors.has(error?.split(':')[0]);
};

function* processErrorAndDispatchFailAction(error, actionFunction) {
  if (isHandledError(error.errors[0].message)) {
    // No availability error is displayed as error alert on reserve page
    const isNoAvailability = error.errors.some((_error) =>
      _error.message.startsWith(ERROR_PAGE_TYPES.NO_AVAILABILITY.value)
    );
    if (!isNoAvailability) {
      // Dispatch error only if error is not NO_AVAILABILITY
      yield dispatchError(error.errors[0].message);
    }
  } else {
    noticeError(new Error(error?.errors[0]?.message), { source: actionFunction?.name });
    if (actionFunction === ACTIONS.actionUpdateReservationFail) {
      // Set error type to RESERVATION_UPDATE
      yield put(actionSetErrorType(ERROR_PAGE_TYPES.RESERVATION_UPDATE.value));
    } else if (actionFunction === ACTIONS.actionCancelReservationFail) {
      yield put(actionSetErrorType(ERROR_PAGE_TYPES.RESERVATION_CANCEL.value));
    } else {
      // Error is unhandled, so set the error type to RESERVATION_UNHANDLED
      yield put(
        actionSetErrorType(ERROR_PAGE_TYPES.RESERVATION_UNHANDLED.value)
      );
    }
  }
  // Call the fail action function
  yield put(actionFunction(error));
}

export function* reservationsListByUserIdRequestHandler(data) {
  try {
    const result = yield callGraphqlWithToken({
      query: listReservationsByUser7R,
      variables: { ...data.payload },
    });
    yield put(
      ACTIONS.actionReservationListByUserIdSuccess(
        result.data.listReservationsByUser7R
      )
    );
  } catch (e) {
    console.log(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionReservationListByUserIdFail(e));
  }
}

export function* setReservation(data) {
  try {
    yield put(
      ACTIONS.actionSetReservationSuccess({ reservation: data.payload })
    );
  } catch (e) {
    console.log(e);
    yield dispatchError(e.message, TYPES.SET_RESERVATION);
    yield put(ACTIONS.actionSetReservationFail(e));
  }
}

export function* getReservationRequestHandler(data) {
  try {
    const result = yield callGraphqlWithToken({
      query: getReservation7R,
      variables: { ...data.payload },
    });
    yield put(
      ACTIONS.actionGetReservationSuccess(result.data.getReservation7R)
    );
  } catch (e) {
    console.log(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionGetReservationFail(e));
  }
}

export function* createReservationRequestHandler(data) {
  yield requestHelper(
    createReservation7R,
    'createReservation7R',
    { input: data.payload },
    ACTIONS.actionCreateReservationSuccess,
    ACTIONS.actionCreateReservationFail
  );
}

export function* updateReservationRequestHandler(data) {
  yield requestHelper(
    updateReservation7R,
    'updateReservation7R',
    { input: data.payload },
    ACTIONS.actionUpdateReservationSuccess,
    ACTIONS.actionUpdateReservationFail
  );
}

export function* cancelReservationRequestHandler(data) {
  yield requestHelper(
    cancelReservation7R,
    'cancelReservation7R',
    { ...data.payload },
    ACTIONS.actionCancelReservationSuccess,
    ACTIONS.actionCancelReservationFail
  );
}

function* requestHelper(
  query,
  resultKey,
  variables,
  actionSuccess,
  actionFail
) {
  let result = null;
  let error = null;
  try {
    yield checkMaintenanceWindow();
    result = yield callGraphqlWithToken({
      query,
      variables,
    });
    error = { errors: result.errors };
  } catch (e) {
    console.error(e);
    error = {
      errors: [
        {
          message: e.message,
        },
      ],
    };
  }

  if (error?.errors?.length > 0) {
    yield processErrorAndDispatchFailAction(error, actionFail);
  } else {
    yield put(actionSuccess(result.data[resultKey]));
  }
}

export default function* reservationsSaga() {
  yield takeLatest(
    TYPES.RESERVATION_LIST_BY_USERID_REQUEST,
    reservationsListByUserIdRequestHandler
  );
  yield takeLatest(TYPES.GET_RESERVATION_REQUEST, getReservationRequestHandler);
  yield takeLatest(TYPES.SET_RESERVATION, setReservation);
  yield takeLatest(
    TYPES.CREATE_RESERVATION_REQUEST,
    createReservationRequestHandler
  );
  yield takeLatest(
    TYPES.UPDATE_RESERVATION_REQUEST,
    updateReservationRequestHandler
  );
  yield takeLatest(
    TYPES.CANCEL_RESERVATION_REQUEST,
    cancelReservationRequestHandler
  );
}
