import React from 'react';
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  makeStyles,
} from '@material-ui/core';

import Typography from '../overrides/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('xs')]: {
      marginBottom: theme.spacing(3),
    },
  },
  content: {
    color: theme.palette.gray[5],
  },
  subContent: {
    color: theme.palette.gray[15],
  },
  listItemAvatar: {
    marginRight: theme.spacing(0),
    [theme.breakpoints.up('xl')]: {
      marginRight: theme.spacing(2),
    },
  },
  avatar: {
    height: 40,
    width: 'auto',
    [theme.breakpoints.up('xl')]: {
      height: 60,
    },
  },
}));

export default function WelcomeItem({ item, classesOverride }) {
  const classes = useStyles({ classes: classesOverride });

  return (
    <ListItem className={classes.root}>
      <ListItemAvatar aria-hidden="true" className={classes.listItemAvatar}>
        <img src={item.icon} alt="" className={classes.avatar} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Typography
              className={classes.content}
              color="secondary"
              component="h3"
              variant="medium1NormalFlex">
              {item.content}
            </Typography>
          </>
        }
      />
    </ListItem>
  );
}
