import React from 'react';
import DefaultError from '../../pages/error/DefaultError';
import { ERROR_PAGE_TYPES } from '../../utils/constants/ErrorPageTypes';
import { noticeError } from '../../utils/newRelic';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    const { location, user } = this.props;
    this.setState({
      error,
      errorInfo,
    });

    if (error != null || errorInfo != null) {
      if (user.session.isAuthenticated) {
        noticeError( new Error(error),
            { path: location.pathname, message: errorInfo});
      }
      console.error('A JavaScript error occurred:', error, errorInfo);
    }
  }

  render() {
    return this.state.error != null || this.state.errorInfo != null ? (
      <DefaultError type={ERROR_PAGE_TYPES.GENERIC.value} showButton={true} />
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
