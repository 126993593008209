import React from 'react';
import { Alert } from '@material-ui/lab';
import { Box, makeStyles } from '@material-ui/core';
import { Info } from '@material-ui/icons/';
import Typography from '../../overrides/Typography';
import clsx from 'clsx';
import CloseButton from '../CloseButton';
import {
  DISMISS_BUTTON_LABEL,
  INFORMATION_BANNER,
  COMMA_JOIN,
} from '../../../assets/copy';
import {
  generateScreenReaderText,
  removeHtmlTags,
} from '../../../utils/stringHelperMethods';
import { isAppleiOS } from '../../../utils/checkApple';
import ScreenReaderText from '../ScreenReaderText';
import { WIDTH_BREAKPOINT } from '../../../utils/constants/Breakpoints';
import { useViewport } from '../../../utils/useViewport';

const useStyles = makeStyles((theme) => ({
  policy: {
    display: 'flex',
    flex: '1 1 auto',
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    background: theme.palette.chip.background,
    borderRadius: ('0px', '2px', '2px', '0px'),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(1.5, 2, 1.5, 1.5),
    },
  },
  policyText: {
    flexBasis: 'auto',
    flexGrow: 1,
    margin: theme.spacing(1.5, 2, 1.5, 0),
    '& .dateList': {
      display: 'list-item',
      marginLeft: '1em',
    },
  },
  alert: {
    flexBasis: 'content',
    padding: theme.spacing(0.5, 0.5, 1, 1.75),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(0.5, 0, 0),
    },
    backgroundColor: 'transparent',
  },
  infoIcon: {
    color: theme.palette.text.primary,
  },
  closeButton: {
    flexBasis: 'content',
    padding: theme.spacing(2),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(1.5, 0),
    },
  },
}));

const InfoBlock = ({
  text,
  containerClassName,
  closeButtonClassName,
  alertClassName,
  showCloseButton,
  policyTextClassName,
  closeButtonHandler,
  children,
  screenReaderText,
}) => {
  const classes = useStyles();
  const { width } = useViewport();
  const isMobile = width < WIDTH_BREAKPOINT;

  const generateText = () => {
    if (screenReaderText) return screenReaderText;

    return generateScreenReaderText(
      ',',
      INFORMATION_BANNER,
      text ? removeHtmlTags(text).replace('&amp;', '&') : undefined
    );
  };

  return (
    <Box
      variant="small2Normal"
      className={clsx(classes.policy, containerClassName)}>
      <Box>
        <Alert
          className={clsx(classes.alert, alertClassName)}
          severity="info"
          iconMapping={{
            info: <Info className={classes.infoIcon} />,
          }}
        />
      </Box>
      <Box className={clsx(classes.policyText, policyTextClassName)}>
        {isAppleiOS && isMobile && (
          <ScreenReaderText>{INFORMATION_BANNER}</ScreenReaderText>
        )}
        <Typography
          variant="medium1Normal"
          role="dialog"
          aria-label={generateText()}>
          {text || children}
        </Typography>
      </Box>
      {showCloseButton && (
        <Box
          className={classes.closeButton}
          onKeyPress={(e) => e.key === 'Enter' && closeButtonHandler()}>
          <CloseButton
            close={closeButtonHandler}
            className={closeButtonClassName}
            fill="#0D74AF"
            height={16}
            width={16}
            label={`${COMMA_JOIN}${DISMISS_BUTTON_LABEL}`}
            tabIndex={0}
            variant="svg"
          />
        </Box>
      )}
    </Box>
  );
};

export default InfoBlock;
