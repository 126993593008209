/* LargeCard will need to get reviewed with reservations with payments
to ensure that badges are added accordingly.  */
import { useState } from 'react';
import {
  makeStyles,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Box,
} from '@material-ui/core';

import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import OutlineButton from '../OutlineButton';
import Typography from '../../overrides/Typography';
import CalendarSvg from '../../svg/Calendar';
import ClockSvg from '../../../assets/icons/clock.svg';
import UserSvg from '../../../assets/icons/user.svg';
import DirectionSvg from '../../../assets/icons/direction.svg';
import DiningSvg from '../../../assets/icons/dining.svg';
import WeblinkSvg from '../../../assets/icons/weblink.svg';
import CallSvg from '../../../assets/icons/call.svg';
import { ReactComponent as BenefitsIcon } from '../../../assets/icons/grv-benefits-small.svg';
import CancelLabel from '../../core/Chips/Label';
import Badge from '../Badge/';
import createInflection from '../../../utils/inflection/createInflection';
import MediaImg from '../../../assets/images/media.png';
import { isAppleiOS } from '../../../utils/checkApple';
import {
  getFormattedDate,
  getTypeFromReservation,
  getScreenReaderFormattedDate,
} from '../../../utils/reservationFilters';
import { generateScreenReaderText } from '../../../utils/stringHelperMethods';
import {
  CANCELED_LABEL,
  CARD_DIRECTIONS_LABEL,
  CARD_MENU_LABEL,
  CARD_SEE_STATUS,
  VENUE_DETAILS_ALT_TEXT,
  RESERVATION_DETAILS_SCREENREADER_TEXT,
  CARD_WEBSITE_LABEL,
  GUEST_INFLECTION,
  RESERVATIONS_SEE_DETAILS,
  EXCLUSIVE_TABLE_TEXT
} from '../../../assets/copy';
import { shortVenueDetails } from '../../../utils/venue';

import ScreenReaderText from '../ScreenReaderText';
import { useViewport } from '../../../utils/useViewport';
import EllipsisText from '../EllipsisText';
import { WIDTH_BREAKPOINT } from '../../../utils/constants/Breakpoints';
import { enterKeyPressHandler } from '../../../utils/keyPresses';
import { fillConfirmedUpdatedTypesList } from '../../../utils/constants/LargeCardTypes';
import { cardHoverStyles } from '../../../utils/constants/hoverStyles';
import { useFeatureFlags } from '../../../utils/useFeatureFlags';

const useStyles = makeStyles((theme) => ({
  accessibilityDescription: {
    display: 'none',
  },
  card: ({ onCardClick }) => ({
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
    borderRadius: theme.spacing(0.5),
    width: 350,
    ...(onCardClick && cardHoverStyles),
    [theme.breakpoints.down('lg')]: {
      borderRadius: theme.spacing(0.5),
      width: '100%',
    },
  }),
  img: {
    maxWidth: '100%',
  },
  date: {
    marginTop: theme.spacing(0.25),
  },
  dateLabel: {
    marginLeft: theme.spacing(0.75),
  },
  property: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  insider: {
    width: '16px',
    height: '16px',
    marginRight: '4px',
    '& path': {
      fill: `${theme.palette.yellow[40]} !important`,
    },
  },
  cardholderLabel: {
    color: theme.palette.secondary['65'],
  },
  buttonLabel: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline !important',
      color: `${theme.palette.interactionBlue[60]} !important`,
    },
    '&:active': {
      color: `${theme.palette.interactionBlue[70]} !important`,
    },
  },
  badge: {
    marginTop: theme.spacing(2),
    height: 24,
  },
  icon: {
    marginLeft: theme.spacing(1.5),
  },
  links: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingTop: theme.spacing(3),
  },
  item: {
    textAlign: 'center',
    width: theme.spacing(8),
    cursor: 'pointer',
  },
  edit: {
    marginRight: theme.spacing(6),
    color: theme.palette.primary[50],
  },
  cancel: {
    color: theme.palette.text.secondary,
    width: 66,
    height: 24,
    borderRadius: 12,
    background: theme.palette.red[50],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  link: {
    textAlign: 'center',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cancelBtn: {
    color: theme.palette.red[50],
    border: 'none !important',
    '&:hover': {
      border: 'none !important',
    },
    width: 'auto',
  },
  editBtn: {
    color: theme.palette.primary.main,
    border: 'none !important',
    '&:hover': {
      border: 'none !important',
    },
    width: 'auto',
  },
}));

const MAX_WIDTH_OF_CARD_TEXT = 300;

const LargeCard = ({
  cardType = 'reservation',
  reservation,
  venue,
  style,
  cardContentStyle,
  type = reservation && getTypeFromReservation(reservation), // Other types are Fill or Cancel (from UpdateReservation)
  onCardClick,
  showActionLinks = true,
  showSeeVenueLink = true,
  linkText,
  details,
  imageStyle,
  focusable = true,
}) => {
  const { refreshLandingPageFlagIsEnabled } = useFeatureFlags();
  const classes = useStyles({ onCardClick });
  const { width } = useViewport();
  const tabIndex = focusable ? 0 : -1;

  const [cardVenue] = useState(reservation?.venue || venue);

  const handleOpenDirections = () => {
    const baseLink = isAppleiOS
      ? 'https://maps.apple.com/maps?q='
      : 'https://maps.google.com/?q=';
    window.open(
      `${baseLink}${cardVenue.address} ${cardVenue.city}, ${cardVenue.state} ${cardVenue.zipcode}`
    );
  };
  const handleOpenWebsite = () => {
    window.open(cardVenue.websiteUrl);
  };
  const handleOpenMenu = () => {
    window.open(cardVenue.menuUrl);
  };
  const handleCallPhoneNumber = () => {
    // NOTE: Does not work in XCode Simulator
    window.open(`tel:${cardVenue.phoneNumber}`);
  };

  const links = [
    {
      icon: DirectionSvg,
      label: CARD_DIRECTIONS_LABEL,
      handleClick: handleOpenDirections,
    },
    {
      icon: WeblinkSvg,
      label: CARD_WEBSITE_LABEL,
      handleClick: handleOpenWebsite, // NOTE: if your db has bad links this doesn't look right
    },
    {
      icon: DiningSvg,
      label: CARD_MENU_LABEL,
      handleClick: handleOpenMenu, // NOTE: if your db has bad links this doesn't look right
    },
  ];
  const callLink = {
    icon: CallSvg,
    label: 'Call',
    handleClick: handleCallPhoneNumber,
  };

  const mapToLinkItem = (linkList) => {
    return linkList.map((item, index) => (
      <Box
        className={classes.item}
        key={index}
        onClick={item.handleClick}
        onKeyPress={item.handleClick}
        tabIndex={0}
        role="link">
        <img src={item.icon} alt="" />
        <Typography variant="medium3Normal">{item.label}</Typography>
      </Box>
    ));
  };

  const renderLinks = () =>
    !!showActionLinks && (
      <Box className={classes.links}>
        {isMobile
          ? mapToLinkItem([callLink].concat(links))
          : mapToLinkItem(links)}
      </Box>
    );

  const cardImage = () => (
    <CardMedia
      component="img"
      alt=""
      height="168"
      className={imageStyle}
      image={cardVenue.images[0] || MediaImg}
    />
  );

  const generateVenueText = () => {
    return generateScreenReaderText(
      ',',
      cardType !== 'venue'
        ? RESERVATION_DETAILS_SCREENREADER_TEXT
        : VENUE_DETAILS_ALT_TEXT,
      cardVenue.name,
      details ?? '',
      cardVenue.address,
      cardVenue.city,
      cardVenue.state,
      cardVenue.zipcode.split('')
    );
  };
  const generateReservationText = () => {
    return generateScreenReaderText(
      ',',
      getScreenReaderFormattedDate(reservation, type),
      reservation.timeSlot || reservation.time,
      createInflection(GUEST_INFLECTION, reservation.guests)
    );
  };

  const renderCardLabel = () =>
    cardType === 'venue'
      ? generateVenueText()
      : generateScreenReaderText(
          ',',
          generateVenueText(),
          generateReservationText()
        );

  const renderCardContentLabel = () => (!onCardClick ? renderCardLabel() : '');
  return (
    <Card
      className={clsx(classes.card, style)}
      onClick={() => onCardClick && onCardClick()}
      onKeyPress={enterKeyPressHandler && onCardClick}
      role={onCardClick != null ? 'link' : 'presentation'}>
      {type === 'Confirmed' && (
        <>
          {onCardClick != null ? (
            <Box
              aria-label={`${generateScreenReaderText(
                ',',
                CARD_SEE_STATUS,
                generateVenueText(),
                generateReservationText()
              )}`}>
              {cardImage()}
            </Box>
          ) : (
            cardImage()
          )}
        </>
      )}
      {type !== 'Confirmed' && cardImage()}

      <CardContent className={cardContentStyle}>
        <ScreenReaderText>{renderCardContentLabel()}</ScreenReaderText>
        <EllipsisText
          aria-hidden="true"
          variant="medium2Semibold"
          maxWidth={width > WIDTH_BREAKPOINT && MAX_WIDTH_OF_CARD_TEXT}
          text={cardVenue.name}
        />

        {cardType === 'venue' && (
          <Typography variant="medium1Normal" aria-hidden="true">
            {shortVenueDetails(cardVenue)}
          </Typography>
        )}

        <EllipsisText
          variant="medium1Normal"
          text={cardVenue.address}
          aria-hidden="true"
        />
        <EllipsisText
          variant="medium1Normal"
          text={`${cardVenue.city}, ${cardVenue.state} ${cardVenue.zipcode}`}
          aria-hidden="true"
        />

        {cardType !== 'venue' && (
          <><Grid
            container
            item
            xs={24}
            direction="row"
            spacing={1}
            className={classes.date}>
            <Grid item>
              <Box display="flex" alignItems="center">
                <CalendarSvg />
                <Typography
                  variant="medium1Normal"
                  className={classes.dateLabel}
                  aria-hidden="true">
                  {getFormattedDate(reservation, type)}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box display="flex" alignItems="center">
                <img src={ClockSvg} alt="" />
                <Typography
                  variant="medium1Normal"
                  aria-hidden="true">
                  {reservation.time || reservation.timeSlot}
                </Typography>
              </Box>
            </Grid>
            
            {((reservation.isCardholderTable || reservation.isExclusive) && refreshLandingPageFlagIsEnabled )&&
                <Grid item>
                  <Box display="flex" style={{"padding-top": "4px"}}>
                    <BenefitsIcon className={classes.insider} role="presentation" />
                    <Typography
                      variant="tiny1Normal"
                      className={classes.cardholderLabel}>
                      {EXCLUSIVE_TABLE_TEXT}
                    </Typography>
                  </Box>
                </Grid>}
                <Grid item xl={4} md={8} xs={6}>
                  <Box display="flex" alignItems="center">
                    <img src={UserSvg} alt="" />
                    <Typography
                      variant="medium1Normal"
                      className={classes.dateLabel}
                      aria-hidden="true">
                      {createInflection(GUEST_INFLECTION, reservation.guests)}
                    </Typography>
                  </Box>
                </Grid>
        </Grid>
          </>
        )}

        {type &&
          type !== 'Confirmed' &&
          type !== 'Completed' &&
          type !== 'Updated' && (
            <Box display="flex" alignItems="center" className={classes.badge}>
              <>
                {type === 'Canceled' ? (
                  <CancelLabel text={CANCELED_LABEL} />
                ) : (
                  type !== 'Updated' && <Badge text={type} />
                )}
              </>
            </Box>
          )}

        {fillConfirmedUpdatedTypesList[type] ? (
          <>
            {renderLinks()}
            {showSeeVenueLink && (
              <Grid
                container
                item
                xs={12}
                justify="space-between"
                alignItems="center"
                className={classes.property}>
                <Link
                  tabIndex={tabIndex}
                  className={classes.link}
                  to={`/venue/${cardVenue.id}`}
                  aria-label={`${linkText} ${cardVenue.name}`}>
                  <Typography
                    variant="small2Semibold"
                    className={classes.buttonLabel}>
                    {linkText}
                  </Typography>
                </Link>
              </Grid>
            )}
          </>
        ) : type === 'Completed' || type === 'Canceled' ? (
          renderLinks()
        ) : type === 'Confirmed' || type === 'Updated' ? (
          <Box mt={1} display="flex">
            <OutlineButton
              variant="medium1Semibold"
              text={RESERVATIONS_SEE_DETAILS}
              style={classes.editBtn}
            />
          </Box>
        ) : null}
      </CardContent>
    </Card>
  );
};

export default LargeCard;
