import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { trackClickWithDescription } from './useOneTag';
import EvergreenImage from '../assets/images/evergreen.jpg';
import EvergreenImageMobile from '../assets/images/evergreen-mobile.jpg';
import {
  HOME_HERO_CAROUSEL_EVERGREEN_TITLE,
  HOME_HERO_CAROUSEL_EVERGREEN_DESCRIPTION,
  HOME_HERO_CAROUSEL_EVERGREEN_BUTTON_TEXT,
} from '../assets/copy';
import {
  FILTER_TYPES,
  VENUE_TYPES,
  VENUE_TYPE_FILTERS,
} from './constants/FilterTypes';
import { actionSetCurrentFilters } from '../store/Filter/FilterAction';
import { useSearchRedirect } from '../components/search/helpers';
import { useViewport } from './useViewport';
import { WIDTH_BREAKPOINT } from './constants/Breakpoints';

export const useEvergreenTile = () => {
  const { width } = useViewport();
  const isMobile = width < WIDTH_BREAKPOINT;
  const history = useHistory();
  const redirectTo = useSearchRedirect();
  const dispatch = useDispatch();
  return {
    evergreenTile: {
      imageUrl: isMobile ? EvergreenImageMobile : EvergreenImage,
      heading: HOME_HERO_CAROUSEL_EVERGREEN_TITLE,
      description: HOME_HERO_CAROUSEL_EVERGREEN_DESCRIPTION,
      primaryButtonText: HOME_HERO_CAROUSEL_EVERGREEN_BUTTON_TEXT,
      primaryButtonOnClick: () => {
        dispatch(
          actionSetCurrentFilters({
            [FILTER_TYPES.VENUE_TYPE]: VENUE_TYPE_FILTERS.filter(
              (type) => type.venueType === VENUE_TYPES.REGULAR
            ),
          })
        );
        trackClickWithDescription(
          'hero-evergreen-browse-button',
          'Button',
          'Evergreen'
        );
        history.push('/search', { redirectTo });
      },
    },
  };
};
