import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../../utils/useAuth';
import ErrorBoundary from './ErrorBoundary';
import TrackedRoute from './TrackedRoute';

const PrivateRoute = ({
  component: Component,
  render = null,
  tracked,
  ...props
}) => {
  const { user } = useAuth();
  const RouteComponent = tracked ? TrackedRoute : Route;

  return (
    <RouteComponent
      {...props}
      render={(routeProps) => {
        if (!user.session.isAuthenticated) return <Redirect to="/" />;
        if (typeof render === 'function') {
          return <ErrorBoundary {...routeProps} user={user}>{render(routeProps)}</ErrorBoundary>;
        }

        return (
          <ErrorBoundary {...routeProps} user={user}>
            <Component {...routeProps} />
          </ErrorBoundary>
        );
      }}
    />
  );
};

export default PrivateRoute;
