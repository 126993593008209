import { call, put, select, takeLatest } from 'redux-saga/effects';

import { getUser } from '../../graphql/customQueries';
import { updateUserProfile } from '../../graphql/customMutations';
import {
  axiosInstance,
  callGraphqlWithToken,
  checkMaintenanceWindow,
  dispatchError,
  getDefaultPaymentAccount,
} from '../helpers';
import { getSilentRefreshInstance } from '../../utils/SilentRefresh';
import * as ACTIONS from './UserAction';
import * as FEATURE_TOGGLE_ACTIONS from '../FeatureToggle/FeatureToggleAction';
import * as RESERVATION_ACTIONS from '../Reservations/ReservationsAction';
import * as TYPES from './UserTypes';
import { SESSION_TOKEN_ROUTE, SESSION_LOGOUT_ROUTE } from '../apiRoutes';

const heapEnabled = process.env.REACT_APP_HEAP_ENABLED === 'true';

function heapIdentifyUser(c1DiningUserId, c1DiningUser, restC1User) {
  if (
    heapEnabled &&
    window.heap &&
    c1DiningUserId != null &&
    c1DiningUser?.c1CustRefId != null &&
    restC1User?.associatedAccounts
  ) {
    const accountReferenceId = restC1User.associatedAccounts
      ?.map((account) => account.associatedAccountReferenceId)
      .filter((id) => !!id)
      .join(',');
    window.heap.identify(c1DiningUserId);
    window.heap.addUserProperties({
      profileReferenceId: c1DiningUser.c1CustRefId,
      accountReferenceId,
    });
  }
}

function* getC1DiningUser(userId) {
  const c1DiningUserResult = yield callGraphqlWithToken({
    query: getUser,
    variables: {
      id: userId,
    },
  });

  const c1DiningUser = c1DiningUserResult.data.getUser;

  return {
    ...c1DiningUser,
    // There can be duplicate diets/allergies, so deduplicate by id
    diets: {
      items: Object.values(
        c1DiningUser.diets.items.reduce(
          (prev, curr) => ({
            ...prev,
            [curr.diet.id]: curr,
          }),
          {}
        )
      ),
    },
    allergies: {
      items: Object.values(
        c1DiningUser.allergies.items.reduce(
          (prev, curr) => ({
            ...prev,
            [curr.allergy.id]: curr,
          }),
          {}
        )
      ),
    },
  };
}

export function* getUserByTokenRequestHandler(data) {
  try {
    yield checkMaintenanceWindow();
    yield put(FEATURE_TOGGLE_ACTIONS.actionGetFeatureTogglesRequest());
    const c1UserResult = yield call(axiosInstance.post, SESSION_TOKEN_ROUTE, {
      grantType: 'jwt',
      token: data.payload.jwt,
      ccid: data.payload.ccid,
    });

    const { c1DiningUserId, features, ...restC1User } = c1UserResult.data;

    const c1DiningUser = yield getC1DiningUser(c1DiningUserId);

    heapIdentifyUser(c1DiningUserId, c1DiningUser, restC1User);

    yield put(
      ACTIONS.actionGetUserByTokenSuccess({
        ...restC1User,
        ...c1DiningUser,
      })
    );

    yield put(
      FEATURE_TOGGLE_ACTIONS.actionGetFeatureTogglesSuccess(new Map(features))
    );

    const defaultAccountDetails = getDefaultPaymentAccount({
      ...restC1User,
      ...c1DiningUser,
    });
    yield put(
      RESERVATION_ACTIONS.actionSetPaymentAccount(defaultAccountDetails)
    );
  } catch (error) {
    yield dispatchError(error.response?.data?.message ?? error.message, SESSION_TOKEN_ROUTE);
    yield put(ACTIONS.actionGetUserByTokenFail(error));
    yield put(FEATURE_TOGGLE_ACTIONS.actionGetFeatureTogglesFailure(error));
  }
}

export function* getUserByAuthCodeRequestHandler(data) {
  try {
    yield checkMaintenanceWindow();
    yield put(FEATURE_TOGGLE_ACTIONS.actionGetFeatureTogglesRequest());
    const c1UserResult = yield call(axiosInstance.post, SESSION_TOKEN_ROUTE, {
      grantType: 'authorization_code',
      token: data.payload.authCode,
      scope: data.payload.scope,
      ccid: data.payload.ccid,
    });

    const { c1DiningUserId, features, ...restC1User } = c1UserResult.data;

    const c1DiningUser = yield getC1DiningUser(c1DiningUserId);

    heapIdentifyUser(c1DiningUserId, c1DiningUser, restC1User);
    yield put(
      ACTIONS.actionGetUserByAuthCodeSuccess({
        ...restC1User,
        ...c1DiningUser,
      })
    );

    yield put(
      FEATURE_TOGGLE_ACTIONS.actionGetFeatureTogglesSuccess(new Map(features))
    );

    const silentRefresh = getSilentRefreshInstance();
    // Skip initializing token refresh interval if already loaded (this would happen if within refresh iframe or coming from step up)
    if (!data.payload.isFromSilentRefresh) {
      silentRefresh.setIsAuthenticated();
      silentRefresh.initializeTokenRefresh();
    }

    if (!data.payload.isFromStepUp) {
      const defaultAccountDetails = getDefaultPaymentAccount({
        ...restC1User,
        ...c1DiningUser,
      });
      yield put(
        RESERVATION_ACTIONS.actionSetPaymentAccount(defaultAccountDetails)
      );
    }

    if (data.payload.isFromSilentRefresh) {
      // we are in the silent refresh iframe; clear its content by setting its src to a blank page
      silentRefresh.revertExpirationTimestamp();
      window.location = 'about:blank';
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message ?? error.message;
    if (errorMessage.startsWith('DINING_PORTAL_REDIRECT')) {
      window.location = process.env.REACT_APP_DINING_PORTAL_REDIRECT_URL;
    } else {
      yield dispatchError(errorMessage, SESSION_TOKEN_ROUTE);
      yield put(ACTIONS.actionGetUserByAuthCodeFail(error));
      yield put(FEATURE_TOGGLE_ACTIONS.actionGetFeatureTogglesFailure(error));
    }
  }
}

export function* getUserRequestHandler(data) {
  try {
    const { user } = yield select((state) => state.user);

    const c1DiningUser = yield getC1DiningUser(user.id);

    yield put(ACTIONS.actionGetUserSuccess(c1DiningUser));
  } catch (error) {
    yield dispatchError(error.message);
    yield put(ACTIONS.actionGetUserFail(error));
  }
}

export function* updateUserRequestHandler(data) {
  try {
    const result = yield callGraphqlWithToken({
      query: updateUserProfile,
      variables: {
        input: data.payload,
      },
    });
    yield put(ACTIONS.actionUpdateUserSuccess(result.data.updateUserProfile));
  } catch (error) {
    yield dispatchError(error.message);
    yield put(ACTIONS.actionUpdateUserFail(error));
  }
}

export function* updateUserDefaultPaymentMethodHandler({ payload }) {
  try {
    const result = yield callGraphqlWithToken({
      query: updateUserProfile,
      variables: {
        input: {
          defaultPaymentMethodAccountId:
            payload.payment.associatedAccountReferenceId,
          id: payload.user.id,
        },
      },
    });
    yield put(ACTIONS.actionUpdateUserSuccess(result.data.updateUserProfile));
    yield put(RESERVATION_ACTIONS.actionSetPaymentAccount(payload.payment));
    yield put(RESERVATION_ACTIONS.actionUpdateDefaultPaymentSuccess());
  } catch (error) {
    yield dispatchError(error.message);
    yield put(ACTIONS.actionUpdateUserFail(error));
  }
}

export function* signoutUserRequestHandler(data) {
  // Skip call to logout endpoint if dummy user since dummy users don't have valid access token
  if (!data.payload.isDummyUser) {
    try {
      yield call(axiosInstance.post, SESSION_LOGOUT_ROUTE, null);
    } catch (error) {
      console.error(`Error calling logout endpoint: ${error}`);
    }
  }

  const silentRefresh = getSilentRefreshInstance();
  silentRefresh.teardown();

  // Always dispatch success action since we still want to fully sign out the user even if logout call fails
  yield put(ACTIONS.actionSignoutUserSuccess());
}

export default function* userSaga() {
  yield takeLatest(
    TYPES.GET_USER_BY_TOKEN_REQUEST,
    getUserByTokenRequestHandler
  );
  yield takeLatest(
    TYPES.GET_USER_BY_AUTH_CODE_REQUEST,
    getUserByAuthCodeRequestHandler
  );
  yield takeLatest(TYPES.UPDATE_USER_REQUEST, updateUserRequestHandler);
  yield takeLatest(TYPES.GET_USER_REQUEST, getUserRequestHandler);
  yield takeLatest(
    TYPES.UPDATE_USER_DEFAULT_PAYMENT_ACCOUNT,
    updateUserDefaultPaymentMethodHandler
  );
  yield takeLatest(TYPES.SIGNOUT_USER_REQUEST, signoutUserRequestHandler);
}
