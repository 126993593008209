import { CircularProgress, makeStyles, Box } from '@material-ui/core';
import { LOADING_TEXT, PROGRESS_BAR_LABEL } from '../../../assets/copy';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  loader: {
    margin: '64px auto',
  },
}));

export const ModalLoading = () => {
  const classes = useStyles();
  return (

    <Box 
      className={classes.root}
      id="loading-spinner"
      aria-live='assertive'
      role="alert"
    >
      <CircularProgress
        size={48}
        className={classes.loader}
        role="alert"
        aria-live='assertive'
        aria-label={PROGRESS_BAR_LABEL}
        title={LOADING_TEXT}
      />
    </Box>
  );
};
