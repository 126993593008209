import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import ScreenReaderText from '../../components/core/ScreenReaderText';
import { Box } from '@material-ui/core';
import Typography from '../overrides/Typography';
import FilledButton from '../../components/core/FilledButton';
import { useViewport } from '../../utils/useViewport';
import {
  RESERVATION_UPDATE_SCREENREADER,
  RESERVATION_EDIT_CONFIRM_SCREENREADER,
  RESERVATION_EDIT_CANCEL_SCREENREADER,
  RESERVATION_TERMS_RESERVE_LINE_ONE,
  RESERVATION_TERMS_RESERVE_LINE_TWO,
  CANCELLATION_POLICY_TEXT,
  CANCELLATION_POLICY_LABEL,
  CANCEL_RESERVATION_BUTTON_TEXT,
  EDIT_RESERVATION_BUTTON_TEXT,
  CREATE_RESERVATION_BUTTON_TEXT,
  RESERVATION_TERMS_TERMS_OF_SERVICE_LABEL,
  RESERVATION_TERMS_PRIVACY_POLICY_LABEL,
  SENTENCE_END,
  RESERVATION_COMPLETE_CONFIRM_NOTICE,
  TERMS_OF_SERVICE_LINK_URL,
  PRIVACY_POLICY_LINK_URL,
} from '../../assets/copy';
import { RESERVATION_TYPE } from '../../utils/constants/Reservation';
import { useEffect, useState } from 'react';
import { validatePhoneNumber } from '../../utils/validatePhoneNumber';
import { focusVisible } from '../../utils/ada/contrastStyles';
import { trackClick } from '../../utils/useOneTag';
const useStyles = makeStyles((theme) => ({
  hyper: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': { color: theme.palette.primary[60] },
    '&:active': { color: theme.palette.primary[70] },
  },
  button: {
    marginTop: theme.spacing(3),
    ...focusVisible(),
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(4),
      width: 'auto',
    },
  },
  cancel: {
    marginTop: theme.spacing(2),
    color: theme.palette.red[50],
  },
}));

const generateScreenTitleText = (type) => {
  let title = '';
  if (type === 'Complete') {
    title = RESERVATION_EDIT_CONFIRM_SCREENREADER;
  } else if (type === 'Upcoming') {
    title = RESERVATION_EDIT_CANCEL_SCREENREADER;
  } else {
    title = RESERVATION_UPDATE_SCREENREADER;
  }
  return title;
};

const widthBreakpoint = 960;

const FormFooter = ({
  type,
  isPaymentDisabled,
  handleReserve,
  handleEdit,
  handleCancel,
  handleUpdated,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { width } = useViewport();
  const { paymentLoading } = useSelector((state) => state?.availability);
  const { user } = useSelector((state) => state.user);
  const { venue } = useSelector((state) => state.venues);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const { draftReservation } = useSelector((state) => state.reservations);
  const location = useLocation();
  const boxParams = {};
  if (width > widthBreakpoint) boxParams.mt = 3;
  if (width <= widthBreakpoint) boxParams.p = 3;

  const handleReserveClick = (event) => {
    trackClick(event, "reserve-now", "Button")
    if (location?.state?.type === RESERVATION_TYPE.UPDATE) {
      handleUpdated()
    } else {
      handleReserve()
    }
  }

  useEffect(() => {
    setIsValidPhoneNumber(validatePhoneNumber(draftReservation?.phoneNumber));
  }, [draftReservation.phoneNumber]);

  const isTestUser = user.isTest;
  const isTestVenue = venue?.isTest;
  const shouldBlockTestUserSubmit = isTestUser && !isTestVenue;
  const shouldDisableSubmitButton =
    !isValidPhoneNumber ||
    shouldBlockTestUserSubmit ||
    paymentLoading ||
    isPaymentDisabled;

  return (
    <>
      <ScreenReaderText component="h2">
        {generateScreenTitleText(type)}
      </ScreenReaderText>
      {(type === 'Complete' || type === 'Update') && (
        <Box {...boxParams} textAlign="left">
          <Typography variant="small2Normal">
            {RESERVATION_TERMS_RESERVE_LINE_ONE}
            <span className={classes.hyper}>
              <a
                href={TERMS_OF_SERVICE_LINK_URL}
                target="_blank"
                rel="noopener noreferrer">
                {RESERVATION_TERMS_TERMS_OF_SERVICE_LABEL}
              </a>
            </span>
            {RESERVATION_TERMS_RESERVE_LINE_TWO}
            <span className={classes.hyper}>
              <a
                href={PRIVACY_POLICY_LINK_URL}
                target="_blank"
                rel="noopener noreferrer">
                {RESERVATION_TERMS_PRIVACY_POLICY_LABEL}
              </a>
            </span>
            {`${SENTENCE_END}${RESERVATION_COMPLETE_CONFIRM_NOTICE}`}
          </Typography>
          <FilledButton
            text={CREATE_RESERVATION_BUTTON_TEXT}
            variant="medium2Normal"
            data-cy="reserve-now"
            onClick={handleReserveClick}
            style={classes.button}
            disabled={shouldDisableSubmitButton}
          />
        </Box>
      )}
      {type === 'Upcoming' && (
        <Box p={3} textAlign="center">
          <Box textAlign="left">
            <Typography component="h3" variant="small2Semibold">
              {CANCELLATION_POLICY_LABEL}
            </Typography>
            <Typography variant="small2Normal">
              {CANCELLATION_POLICY_TEXT}
            </Typography>
          </Box>
          <FilledButton
            text={EDIT_RESERVATION_BUTTON_TEXT}
            variant="medium2Normal"
            onClick={() => handleEdit(history)}
            style={classes.button}
          />
          <Typography
            variant="medium2Normal"
            onClick={() => handleCancel(history)}
            className={classes.cancel}>
            {CANCEL_RESERVATION_BUTTON_TEXT}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default FormFooter;
