import React, { useCallback, useState, useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import Welcome from './Welcome';
import Location from './Location';
import { actionSetAnimationSlideLeft } from '../../store/Animations/AnimationsAction';
import { useAuth } from '../../utils/useAuth';
import { useHistory, useLocation } from 'react-router';
import { SELECT_BUTTON_LABEL } from '../../assets/copy';
import { getPermalinkRouteRedirectLocation } from './onboardingHelpers';
import useOnboarding from '../../utils/useOnboarding';
import {
  getPermalinkRouteRedirect,
  clearPermalinkRouteRedirect,
  clearPermalinkSlug,
} from '../../utils/sessionStorageHelper';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.beacon.surf,
    color: theme.palette.beacon.midnight,
    display: 'flex',
    [theme.breakpoints.up('xl')]: {
      height: '100vh',
    },
  },
}));

const Onboarding = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const markets = useSelector((state) => state.markets);
  const [list, setList] = useState(markets.list);
  const [shouldOnboard, setShouldOnboard] = useState(true);
  const { handleRestaurant } = useOnboarding();

  const {
    user,
    signinWithJwt,
    searchParams: { assertion },
  } = useAuth();

  useEffect(() => {
    signinWithJwt(assertion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user == null) return;
    if (!user.session?.isAuthenticated) return;

    //clear session storage for redirection when user gets to the FTUX
    if (getPermalinkRouteRedirect() === '/onboarding') {
      clearPermalinkRouteRedirect();
    }

    // Skip onboarding on load if user already selected default city
    if (user.marketId != null && activeStep === 0) {
      setShouldOnboard(false);
      history.push({
        pathname: getPermalinkRouteRedirectLocation(),
        search: location.search,
      });
    }
  }, [activeStep, user, history, location]);

  useEffect(() => {
    try {
      const result = {
        id: '0',
        name: SELECT_BUTTON_LABEL,
      };
      setList([result, ...markets.list]);
    } catch (e) {
      console.error(e);
    }
  }, [markets?.list]);

  const dispatch = useDispatch();
  const totalSteps = 2;

  const handleNext = useCallback(() => {
    dispatch(actionSetAnimationSlideLeft(true));
    setActiveStep((activeStep) => activeStep + 1);
  }, [dispatch, setActiveStep]);

  const handleBack = () => {
    setActiveStep((activeStep) => activeStep - 1);
  };

  useEffect(() => {
    document.body.style.backgroundColor = '#10253F';
    return () => {
      document.body.style.backgroundColor = 'white';
      clearPermalinkSlug();
      clearPermalinkRouteRedirect();
    };
  }, []);

  return (
    shouldOnboard &&
    user.session.isAuthenticated && (
      <Box className={classes.root}>
        {activeStep === 0 && (
          <Welcome
            active={activeStep === 0}
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
            totalSteps={totalSteps}
          />
        )}
        {activeStep === 1 && (
          <Location
            active={activeStep === 1}
            handleNext={handleRestaurant}
            handleBack={handleBack}
            list={list}
            activeStep={activeStep}
            totalSteps={totalSteps}
          />
        )}
      </Box>
    )
  );
};

export default Onboarding;
