import moment from 'moment';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PAYMENT_RULE_TYPE } from './helpers/paymentAvailabilityTypes';
import CommonLoading from '../common/CommonLoading';

import { generateAvailabilityInput } from '../../utils/generateAvailabilityInput';
import {
  actionClearAvailability,
  actionGetAvailabilityRequest,
} from '../../store/Availability/AvailabilityAction';
import { FETCH_STATUS } from '../../utils/constants/FetchStatus';
import { RESERVATION_TYPE } from '../../utils/constants/Reservation';
import ReservationPageWrapper from './ReservationPageWrapper';
import { actionGetUserRequest } from '../../store/User/UserAction';
import { actionSetPaymentAccount } from '../../store/Reservations/ReservationsAction';
import { getDefaultPaymentAccount } from '../../store/helpers';

const ReservationPage = ({ reservationType, ...props }) => {
  const location = useLocation();
  const [stripePromise, setStripePromise] = useState();
  const { draftReservation, stepUp } = useSelector(
    (state) => state.reservations
  );
  const { user } = useSelector((state) => state.user);
  const [type, setType] = useState(reservationType);
  const [mounted, setMounted] = useState(false);
  const { availability } = useSelector((state) => state.availability);
  const [reservationAvailability, setReservationAvailability] = useState({});
  const reservationStatus = useSelector((state) => state?.reservations?.status);

  useEffect(() => {
    // Don't set mounted to false if this is bottom sheet or modal since it would hide the spinner being rendered by that component
    if (reservationStatus === FETCH_STATUS.FETCHING && !props.isModal) {
      setMounted(false);
    }
  }, [reservationStatus, props.isModal]);

  const dispatch = useDispatch();

  const options = {
    fonts: [
      {
        cssSrc:
          'https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap',
      },
    ],
  };

  useEffect(() => {
    return () => {
      dispatch(actionClearAvailability());
    };
  }, [dispatch]);

  useEffect(() => {
    const { venue, timeSlot, date, guests, isAvailable } = draftReservation;
    if (isAvailable === false) return;
    const input = generateAvailabilityInput(
      venue,
      date,
      timeSlot,
      guests,
      false,
      true
    );
    dispatch(actionGetAvailabilityRequest(input));
    dispatch(actionGetUserRequest());

    // Skip setting default account if redirected from step up
    if (stepUp?.shouldRedirect) return;
    // Skip setting default account if bottom sheet and coming back from edit payment method screen
    if (props.isModal && props.isPaymentMethodEdited) return;

    const defaultAccount = getDefaultPaymentAccount(user);
    dispatch(actionSetPaymentAccount(defaultAccount));
    /*
     * Re-set redux store reservation payment method to default payment when unmount.
     * This is to prevent situation where users change payment then go to another screen.
     * When user comes back to pages with Payment Methods, it should show default payment
     * method again, not the previously selected method.
     */
    return () => dispatch(actionSetPaymentAccount(defaultAccount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // set the fetched availabilities and assign it to the reservationAvailabilities
    setReservationAvailability(availability);
  }, [availability]);

  useEffect(() => {
    if (type === undefined) {
      if (location?.state?.type === RESERVATION_TYPE.UPDATE) {
        setType('Update');
      } else if (location.pathname === '/reserve') {
        setType('Complete');
      } else if (location.pathname === 'reservation/edit') {
        setType('Upcoming');
      }
    }
  }, [location.pathname, location.state, type]);

  useEffect(() => {
    if (
      stripePromise !== undefined &&
      type !== undefined &&
      !mounted &&
      reservationStatus !== FETCH_STATUS.FETCHING
    ) {
      setMounted(true);
    }
  }, [type, stripePromise, mounted, reservationStatus]);

  useEffect(() => {
    let venuePayment = null;

    if (draftReservation && reservationAvailability) {
      let venueOnSpecifiedTime = null;
      const { timeSlot: time, slotOptionId } = draftReservation;
      const venueTime = moment(time, ['h:mm A']).format('h:mm A'); //in case the time gets a different format
      if (
        !reservationAvailability ||
        !reservationAvailability.paymentInformation
      ) {
        setStripePromise(null);
        return;
      }

      venueOnSpecifiedTime = reservationAvailability?.timeslots.find(
        (timeslot) =>
          timeslot.timeSlot === venueTime &&
          timeslot.slotOptionId === slotOptionId
      );

      if (!venueOnSpecifiedTime || !venueOnSpecifiedTime.payment) {
        setStripePromise(null);
        return;
      }

      venuePayment = venueOnSpecifiedTime.payment;
      const paymentRule = venuePayment.ccPaymentRule; // advanced_payment or save_for_later or null (no stripe)

      let stripeSevenRoomsPublicKey = null;
      let accountId = null;
      if (
        venueOnSpecifiedTime.requiresCreditCard &&
        (paymentRule === PAYMENT_RULE_TYPE.ADVANCED_PAYMENT ||
          paymentRule === PAYMENT_RULE_TYPE.SAVE_FOR_LATER)
      ) {
        stripeSevenRoomsPublicKey =
          reservationAvailability.paymentInformation.sevenroomsPublicKey;
        accountId = reservationAvailability.paymentInformation.accountId;

        // move the code below above
        if (!(stripeSevenRoomsPublicKey && accountId)) {
          setStripePromise(null);
          return;
        }

        setStripePromise(
          loadStripe(stripeSevenRoomsPublicKey, {
            stripeAccount: accountId,
          })
        );
      } else {
        setStripePromise(null);
      }
    }
  }, [draftReservation, reservationAvailability, stepUp?.errorCode]);

  return (
    <>
      {!props.isModal && <CommonLoading active={!mounted} />}
      {mounted && (
        <Elements stripe={stripePromise} options={options}>
          <ReservationPageWrapper type={type} {...props} />
        </Elements>
      )}
    </>
  );
};

export default ReservationPage;
