import React from 'react';
import { SwipeableDrawer } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Typography from '../../overrides/Typography';
import clsx from 'clsx';
import {
  UiCloseLined,
  UiChevronLeftLined,
} from '@cof/omni-gravity-icons-react';

const useStyles = makeStyles((theme) => ({
  drawer: {
    '& .MuiDrawer-paper': {
      borderRadius: '12px 12px 0 0',
      padding: theme.spacing(1, 0, 0),
      maxHeight: '90%',
    },
  },
  header: {
    padding: theme.spacing(0, 2, 1),
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: ({ subtitle }) =>
      subtitle ? theme.spacing(0.5, 0, 1) : theme.spacing(1, 0),
    textAlign: 'center',
    gap: theme.spacing(1),
  },
  headerTitle: {
    width: '100%',
  },
  headerSubtitle: {
    color: theme.palette.secondary[65],
    fontSize: '12px',
    lineHeight: '12px',
  },
  underlinedHeader: {
    borderBottom: `1px solid ${theme.palette.spoon.gray[10]}`,
  },
  pointer: {
    cursor: 'pointer',
  },
  invisible: {
    visibility: 'hidden',
  },
  handle: {
    width: '48px',
    height: '4px',
    borderRadius: '2px',
    backgroundColor: theme.palette.secondary[65],
    margin: 'auto',
    marginBottom: ({ subtitle }) => !subtitle && theme.spacing(1),
  },
  content: {
    overflowY: 'auto',
  },
}));

export const BottomDrawer = ({
  open,
  onBackButtonClick,
  onClose,
  title,
  subtitle,
  disableBackdropClick,
  children,
  ...rest
}) => {
  const classes = useStyles({ subtitle });
  return (
    <SwipeableDrawer
      open={open}
      onOpen={() => {}} // no-op to prevent PropType warning
      onClose={onClose}
      anchor="bottom"
      ModalProps={{
        BackdropProps: {
          invisible: true,
        },
      }}
      disableBackdropClick={disableBackdropClick}
      className={classes.drawer}
      {...rest}>
      <div
        className={clsx(classes.header, {
          [classes.underlinedHeader]: subtitle,
        })}>
        <div className={classes.handle} />
        <div className={classes.headerContent}>
          <UiChevronLeftLined
            className={clsx({
              [classes.pointer]: true,
              [classes.invisible]: !onBackButtonClick,
            })}
            onClick={onBackButtonClick}
            aria-label="back button"
            role="button"
            tabIndex="0"
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                onBackButtonClick();
              }
            }}
          />
          <div>
            <Typography
              variant="medium1Semibold"
              className={classes.headerTitle}
              component="h2">
              {title}
            </Typography>
            <Typography
              variant="small1Normal"
              className={classes.headerSubtitle}>
              {subtitle}
            </Typography>
          </div>
          <UiCloseLined
            className={classes.pointer}
            onClick={onClose}
            aria-label="close drawer button"
            role="button"
            tabIndex="0"
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                onClose();
              }
            }}
          />
        </div>
      </div>
      <div className={classes.content}>{children}</div>
    </SwipeableDrawer>
  );
};

export default BottomDrawer;

BottomDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onBackButtonClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  disableBackdropClick: PropTypes.bool,
};
