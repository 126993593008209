import React from 'react';
import clsx from 'clsx';
import { Link, Button as MuiButton, withStyles } from '@material-ui/core';
import { UiChevronRightLined } from '@cof/omni-gravity-icons-react';
import '@cof/optimist/capitalone.css'; // TODO: remove this line once PR #303 is merged

export const Button = withStyles((theme) => ({
  root: {
    fontFamily: 'Optimist, sans-serif',
    letterSpacing: 'normal',
    color: theme.palette.spoon.white[100],
    backgroundColor: theme.palette.spoon.blue[40],
    borderRadius: '4px',
    boxShadow: 'none',
    boxSizing: 'border-box',
    fontWeight: 600,
    textTransform: 'none',
    lineHeight: '20px',
    padding: '9px 16px 11px',
    '&:hover': {
      backgroundColor: theme.palette.spoon.blue[50],
      boxShadow: 'none',
    },
    '& .MuiTouchRipple-child': {
      backgroundColor: theme.palette.spoon.black[100],
    },
    '& .MuiButton-label': {
      zIndex: 10,
      position: 'relative',
    }
  },
  light: {
    color: theme.palette.spoon.black[90],
    backgroundColor: theme.palette.spoon.white[100],
    '&:hover': {
      backgroundColor: theme.palette.spoon.gray[5],
      boxShadow: 'none',
    },
  },
  large: {
    padding: '16px 32px 18px',
    fontSize: '20px',
    lineHeight: '28px',
    borderRadius: '8px',
  },
  medium: {
    padding: '11px 24px 13px',
    fontSize: '16px',
    lineHeight: '24px',
    borderRadius: '6px',
  },
}))(({ children, classes, variant, size, ...rest }) => (
  <MuiButton
    className={clsx({
      [classes.root]: true,
      [classes.light]: variant === 'light',
      [classes.large]: size === 'large',
      [classes.medium]: size === 'medium',
    })}
    variant="contained"
    {...rest}>
    {children}
  </MuiButton>
));

export const OutlinedButton = withStyles((theme) => ({
  root: {
    fontFamily: 'Optimist, sans-serif',
    letterSpacing: 'normal',
    color: theme.palette.spoon.blue[40],
    backgroundColor: 'white',
    borderColor: theme.palette.spoon.blue[40],
    borderWidth: '1px',
    borderRadius: '4px',
    boxSizing: 'border-box',
    fontWeight: 600,
    textTransform: 'none',
    lineHeight: '20px',
    padding: '9px 16px 11px',
    '&:hover': {
      backgroundColor: theme.palette.spoon.gray[5],
      boxShadow: 'none',
    },
    '& .MuiTouchRipple-child': {
      backgroundColor: theme.palette.spoon.blue[20],
    },
    '& .MuiButton-label': {
      zIndex: 10,
      position: 'relative',
    }
  },
  large: {
    padding: '16px 32px 18px',
    fontSize: '20px',
    lineHeight: '28px',
    borderRadius: '8px',
  },
  medium: {
    padding: '11px 24px 13px',
    fontSize: '16px',
    lineHeight: '24px',
    borderRadius: '6px',
  },
}))(({ children, classes, size, ...rest }) => (
  <MuiButton
    variant="outlined"
    className={clsx({
      [classes.root]: true,
      [classes.large]: size === 'large',
      [classes.medium]: size === 'medium',
    })}
    {...rest}>
    {children}
  </MuiButton>
));

export const TextButton = withStyles((theme) => ({
  root: {
    letterSpacing: 'normal',
    borderRadius: '4px',
    color: theme.palette.spoon.blue[40],
    fontFamily: 'Optimist, sans-serif',
    fontWeight: 600,
    textTransform: 'none',
    lineHeight: '20px',
    padding: '9px 16px 11px',
    '&:hover': {
      backgroundColor: theme.palette.spoon.gray[5],
      boxShadow: 'none',
    },
    '& .MuiTouchRipple-child': {
      backgroundColor: theme.palette.spoon.blue[20],
    },
    '& .MuiButton-label': {
      zIndex: 10,
      position: 'relative',
    }
  },
  large: {
    padding: '16px 32px 18px',
    fontSize: '20px',
    lineHeight: '28px',
    borderRadius: '8px',
  },
  medium: {
    padding: '11px 24px 13px',
    fontSize: '16px',
    lineHeight: '24px',
    borderRadius: '6px',
  },
}))(({ children, classes, size, ...rest }) => (
  <MuiButton
    className={clsx({
      [classes.root]: true,
      [classes.large]: size === 'large',
      [classes.medium]: size === 'medium',
    })}
    {...rest}>
    {children}
  </MuiButton>
));

export const CtaLink = withStyles((theme) => ({
  root: {
    letterSpacing: 'normal',
    display: 'inline-flex',
    alignItems: 'center',
    color: theme.palette.spoon.blue[40],
    fontFamily: 'Optimist, sans-serif',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    textTransform: 'none',
    marginRight: 'auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  chevron: {
    width: '16px',
    height: '16px',
    marginLeft: theme.spacing(0.75),
  },
  largeChevron: {
    width: '24px',
    height: '24px',
  },
  light: {
    color: theme.palette.spoon.white[100],
  },
  large: {
    padding: '16px 0px 18px',
    fontSize: '20px',
    lineHeight: '28px',
  },
  medium: {
    fontSize: 16,
    lineHeight: '24px',
  },
}))(({ children, classes, variant, size, ...rest }) => (
  <Link
    component="button"
    className={clsx({
      [classes.root]: true,
      [classes.light]: variant === 'light',
      [classes.large]: size === 'large',
      [classes.medium]: size === 'medium',
    })}
    {...rest}>
    {children}
    <UiChevronRightLined
      className={clsx({
        [classes.chevron]: true,
        [classes.largeChevron]: size === 'large',
      })}
    />
  </Link>
));
