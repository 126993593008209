import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {
  actionClearReservationCreateStatus,
  actionClearReservationPaymentRedirectFromStepUp,
  actionClearReservationUpdateStatus,
  actionCreateReservationRequest,
  actionGetReservationRequest,
  actionSetDraftReservation,
} from '../store/Reservations/ReservationsAction';
import { RESERVATION_TYPE } from './constants/Reservation';
import { C1_CALLBACK_ERROR_CODES, useAuth } from './useAuth';
import {
  RESERVE_STRIPE_STEP_UP_ABORTED,
  RESERVE_STRIPE_STEP_UP_OR_PAYMENT_FAILED,
} from '../assets/copy';
import { sanitizeTotalAmount, toCents } from './currencyHelper';
import {
  CREDIT_CARD_ERROR_TYPE,
  GRATUITY_TYPE,
  PAYMENT_RULE_TYPE,
} from '../pages/reserve/helpers/paymentAvailabilityTypes';
import { generateISOString, isDateInBetween } from './dateTime';
import {
  getPaymentNonce,
  handlePaymentIntentStripe,
  handleSetupIntentStripe,
} from '../pages/reserve/helpers/paymentHandlers';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import {
  convertUserReservationItemsForDraft,
  createReservationPayload,
} from './reservationFilters';
import {
  actionUpdateUserDefaultPaymentAccount,
  actionUpdateUserRequest,
} from '../store/User/UserAction';
import moment from 'moment';
import { handleReserveError } from '../pages/reserve/helpers/reserveHelpers';

export const PAYMENT_STATUS_TYPES = Object.freeze({
  SUCCESS: 'success',
  FAIL: 'fail',
  STEP_UP: 'step_up',
});

class PaymentNonceDetailsResponse {
  constructor({ statusType, details }) {
    this.statusType = statusType;
    this.details = details;
  }
}

export const useReserve = (props = {}) => {
  const { isModal } = props;

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const stripe = useStripe();
  const elements = useElements();

  const { venue } = useSelector((state) => state.venues);
  const { user, redirectToC1Challenge } = useAuth();
  const {
    draftReservation,
    paymentAccount,
    reservation,
    reservationCreateStatus,
    reservationUpdateStatus,
    status: reservationFetchStatus,
    stepUp,
  } = useSelector((state) => state?.reservations);
  const { availability, paymentLoading } = useSelector(
    (state) => state.availability
  );
  const venuePayment = draftReservation?.payment;
  const paymentRule = venuePayment?.ccPaymentRule;

  const isReservationNotAvailable =
    reservationCreateStatus?.error?.errors?.[0]?.message?.includes(
      'NO_AVAILABILITY'
    ) ||
    reservationUpdateStatus?.error?.errors?.[0]?.message?.includes(
      'NO_AVAILABILITY'
    ) ||
      (draftReservation.isAvailable === false)
  ;

  const [specialOccasionsWithDatesFlag, setSpecialOccasionsWithDatesFlag] =
    useState({
      Birthday: false,
      Anniversary: false,
    });
  const { navigationMenuVisible, specialOccasions } = useSelector(
    (state) => state.appData
  );
  const userReservation = moment(draftReservation.date, 'YYYY-MM-DD');
  const userAnniversary = moment(user.userAnniversary, 'MM-DD-YYYY');
  const userBirthday = moment(user.userBirthday, 'MM-DD-YYYY');
  const partnerBirthday = moment(user.partnerBirthday, 'MM-DD-YYYY');

  const [mounted, setMounted] = useState(false);
  const [showInfoBlock, setShowInfoBlock] = useState(false);
  const [shouldShowLoader, setShouldShowLoader] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [originalAmount, setOriginalAmount] = useState(0);
  const [paymentGratuity, setPaymentGratuity] = useState(0);
  const [paymentSubTotal, setPaymentSubTotal] = useState(0);
  const [paymentServiceCharge, setPaymentServiceCharge] = useState(0);
  const [paymentTax, setPaymentTax] = useState(0);
  const [venuePaymentPolicy, setVenuePaymentPolicy] = useState('');
  const [customTip, setCustomTip] = useState(0);
  const [cardNumberError, setCardNumberError] = useState('');
  const [cardCardExpiryError, setCardExpiryError] = useState('');
  const [cardCvcError, setCardCvcError] = useState('');
  const [shouldShowStripeForm, setShouldShowStripeForm] = useState(false);
  const [stripeAlertMessage, setStripeAlertMessage] = useState(null);
  const [redirectToConfirmPage, setRedirectToConfirmPage] = useState(false);
  const [venueMerchantId, setVenueMerchantId] = useState(null);
  const [isPaymentCalculated, setIsPaymentCalculated] = useState(false);
  const [tipProperties, setTipProperties] = useState({
    18: {
      percentage: 0.18,
      isSelected: false,
    },
    20: {
      percentage: 0.2,
      isSelected: false,
    },
    25: {
      percentage: 0.25,
      isSelected: false,
    },
    30: {
      percentage: 0.3,
      isSelected: false,
    },
    custom: {
      isSelected: false,
    },
  });
  const [reservationObj, setReservationObj] = useState({}); // the value of reservation is based from the venue.payments object 7Rs. This will be hardcoded for now
  const [isPaymentDisabled, setIsPaymentDisabled] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [name, setName] = useState('');

  const handleSetName = (e) => {
    setName(e.target.value);
  };

  const checkForSpecialDates = (
    reservationDate,
    specialOccasionDate,
    occasionType
  ) => {
    if (isDateInBetween(reservationDate, specialOccasionDate)) {
      if (!showInfoBlock) {
        setShowInfoBlock(true); // if any of this flag has been switched to true and hasn't been set to true yet then we will display the InfoBlock, instead of looping through all of the object to check if there's a true
      }
      setSpecialOccasionsWithDatesFlag((prev) => {
        return { ...prev, [occasionType]: true };
      });
    }
  };

  useEffect(() => {
    if (draftReservation && draftReservation.venue) {
      setMounted(true);
    }
  }, [draftReservation]);

  useEffect(() => {
    // If not coming from step up redirect, hide the spinner
    if (!stepUp?.shouldRedirect) {
      setShouldShowLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleReserveError(
      setShowAlert,
      setIsPaymentDisabled,
      isReservationNotAvailable
    );
  }, [history, isReservationNotAvailable, reservationFetchStatus]);

  useEffect(() => {
    window.oneTag.track('view', {
      event_name: 'page_view',
      page_title: window.location.pathname,
      merchant_id: draftReservation.venue.sevenRoomsVenueId,
      merchant_name: draftReservation.venue.name,
      merchant_date: draftReservation?.realDateTime && draftReservation?.venue?.timezoneName
          ? generateISOString(draftReservation.realDateTime, draftReservation.venue.timezoneName)
          : null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This useEffect should only run on the Create Reservation feature
  useEffect(() => {
    if (!isPaymentCalculated || venueMerchantId == null) return;
    if (location.state?.type === RESERVATION_TYPE.UPDATE) return;
    if (!stepUp?.shouldRedirect) return;
    dispatch(actionClearReservationPaymentRedirectFromStepUp());

    if (stepUp?.errorCode != null) {
      console.warn(
        `Step up error code: ${stepUp.errorCode}; fallback to Stripe form`
      );

      const stripeAlertCopy =
        stepUp.errorCode === C1_CALLBACK_ERROR_CODES.STEP_UP_ABORTED
          ? RESERVE_STRIPE_STEP_UP_ABORTED
          : RESERVE_STRIPE_STEP_UP_OR_PAYMENT_FAILED;

      setStripeAlertMessage(stripeAlertCopy);
      setShouldShowStripeForm(true);
      setShouldShowLoader(false);
      return;
    }

    handleReserve();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentCalculated, venueMerchantId]);

  // This useEffect should only run on the Create Reservation feature
  useEffect(() => {
    if (location.state?.type !== RESERVATION_TYPE.UPDATE) {
      checkForSpecialDates(userReservation, userAnniversary, 'Anniversary');
      checkForSpecialDates(userReservation, userBirthday, 'Birthday');
      checkForSpecialDates(userReservation, partnerBirthday, 'Birthday');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This useEffect should only run on the Create Reservation feature
  useEffect(() => {
    if (stepUp?.shouldRedirect) return;
    if (location.state?.type !== RESERVATION_TYPE.UPDATE) {
      const filteredSpecialDates = Object.keys(
        specialOccasionsWithDatesFlag
      ).filter((specialDates) => specialOccasionsWithDatesFlag[specialDates]);
      const reservationsOccasions = convertUserReservationItemsForDraft(
        specialOccasions,
        filteredSpecialDates
      );
      const draftReservationClone = { ...draftReservation };
      draftReservationClone.specialOccasions = reservationsOccasions;
      dispatch(actionSetDraftReservation(draftReservationClone));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialOccasionsWithDatesFlag]);

  useEffect(() => {
    // TODO standardize this - store the availability in one place only, ideally in availability state
    if (availability?.paymentInformation?.accountId != null) {
      setVenueMerchantId(availability.paymentInformation.accountId);
    } else if (
      draftReservation.venue.availability != null &&
      draftReservation.venue.availability.length > 0
    ) {
      setVenueMerchantId(
        draftReservation.venue.availability[0].paymentInformation.accountId
      );
    }
  }, [availability, draftReservation.venue.availability]);

  useEffect(() => {
    if (
      reservation != null &&
      (reservationCreateStatus?.success || reservationUpdateStatus?.success)
    ) {
      // Update default payment account if users select new default payment method
      if (
        paymentAccount?.isDefaultPaymentEdited &&
        reservationCreateStatus?.success
      ) {
        dispatch(
          actionUpdateUserDefaultPaymentAccount({
            payment: paymentAccount,
            user,
          })
        );

        // Hide payment method alert when users choose a default method
        // even if they don't dismiss the message
        dispatch(
          actionUpdateUserRequest({
            hidePaymentMethodAlert: true,
            id: user.id,
          })
        );
      }
      if (!isModal) {
        dispatch(actionGetReservationRequest({ id: reservation.id }));
      }
      setRedirectToConfirmPage(true);
    }
    return () => {
      dispatch(actionClearReservationCreateStatus());
      dispatch(actionClearReservationUpdateStatus());
    };
  }, [
    dispatch,
    reservationCreateStatus,
    reservationUpdateStatus,
    reservation,
    paymentAccount,
    user,
    isModal,
  ]);

  useEffect(() => {
    if (venuePayment == null) return;
    const {
      pregratuityTotal,
      mandatedGratuityAmt,
      gratuityType,
      taxAmt,
      serviceChargeAmt: serviceCharge,
      subtotal,
      paymentPolicy,
    } = venuePayment;

    if (gratuityType === GRATUITY_TYPE.SPECIFIC_GRATUITY) {
      const amount = sanitizeTotalAmount(pregratuityTotal, mandatedGratuityAmt);
      setTotalAmount(amount);
      setOriginalAmount(amount);
      setPaymentGratuity(mandatedGratuityAmt);
      setPaymentSubTotal(subtotal);
      setPaymentServiceCharge(serviceCharge);
      setPaymentTax(taxAmt);
      setVenuePaymentPolicy(paymentPolicy);
      setReservationObj(venuePayment);
      setIsPaymentCalculated(true);
    } else if (gratuityType === GRATUITY_TYPE.CLIENT_GRATUITY) {
      const amount = sanitizeTotalAmount(pregratuityTotal, 0);
      setTotalAmount(amount);
      setOriginalAmount(amount);
      setPaymentSubTotal(subtotal);
      setPaymentServiceCharge(serviceCharge);
      setPaymentTax(taxAmt);
      setVenuePaymentPolicy(paymentPolicy);
      setReservationObj(venuePayment);
      setIsPaymentCalculated(true);

      // On redirect from step up, get paymentGratuity from draftReservation and set on useState
      if (stepUp?.shouldRedirect) {
        setPaymentGratuity(draftReservation.paymentGratuity);
      }
    } else {
      setReservationObj({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venuePayment, draftReservation.paymentGratuity]);

  const setErrorMessages = (error) => {
    if (CREDIT_CARD_ERROR_TYPE.INCOMPLETE_NUMBER === error.code) {
      setCardNumberError(error.message);
    } else if (CREDIT_CARD_ERROR_TYPE.INCOMPLETE_EXPIRY === error.code) {
      setCardExpiryError(error.message);
    } else if (CREDIT_CARD_ERROR_TYPE.INCOMPLETE_CVC === error.code) {
      setCardCvcError(error.message);
    } else if (1000 < error.code) {
      console.log(error.message);
    }
  };

  const clearErrorMessages = () => {
    setCardNumberError('');
    setCardExpiryError('');
    setCardCvcError('');
  };

  const getPaymentNonceDetails = async () => {
    try {
      if (!user.isPushProvisioningEnabled) {
        throw new Error('User does not have push provisioning enabled');
      }

      const getPaymentNoncePayload = {
        associatedAccountReferenceId:
          paymentAccount.associatedAccountReferenceId,
        lastFourCardNumber: paymentAccount.accountDetails.lastFourCardNumber,
        reservationDateTime: generateISOString(
          draftReservation.realDateTime,
          draftReservation.venue.timezoneName
        ),
        forwardEndMerchantId: venueMerchantId,
        profileReferenceId: user.c1CustRefId,
        ccid: user.session.ccid,
      };
      const nonce = await getPaymentNonce(getPaymentNoncePayload);

      const details = {
        paymentIntermediary: 'capitalonebraintree',
        paymentIntermediaryToken: nonce,
        paymentCorrelationId: user.session.ccid,
        paymentProfileReferenceId: user.c1CustRefId,
      };

      return new PaymentNonceDetailsResponse({
        statusType: PAYMENT_STATUS_TYPES.SUCCESS,
        details,
      });
    } catch (error) {
      console.error(error);

      if (error.message?.startsWith('FORBIDDEN')) {
        const stateSuffix = isModal ? 'reserveModal' : 'reserve';
        redirectToC1Challenge(stateSuffix);
        return new PaymentNonceDetailsResponse({
          statusType: PAYMENT_STATUS_TYPES.STEP_UP,
        });
      }

      console.warn('Falling back on Stripe form');
      setStripeAlertMessage(RESERVE_STRIPE_STEP_UP_OR_PAYMENT_FAILED);
      setShouldShowStripeForm(true);
      setShouldShowLoader(false);

      return new PaymentNonceDetailsResponse({
        statusType: PAYMENT_STATUS_TYPES.FAIL,
      });
    }
  };

  // TODO refactor this to reduce cognitive complexity
  const handleReserve = async () => {
    setShouldShowLoader(true);
    if (paymentRule === PAYMENT_RULE_TYPE.ADVANCED_PAYMENT) {
      clearErrorMessages();
      setIsPaymentDisabled(true);

      let error = null;
      let statusType = null;
      let intentId = null;
      let paymentNonceDetails = {};

      if (shouldShowStripeForm) {
        const response = await handlePaymentIntentStripe(
          stripe,
          elements,
          totalAmount,
          draftReservation.venue.sevenRoomsVenueId || venue.sevenRoomsVenueId,
          name
        );
        error = response.error;
        statusType = response.statusType;
        intentId = response.intentId;
      } else {
        const response = await getPaymentNonceDetails();
        error = response.error;
        statusType = response.statusType;
        paymentNonceDetails = response.details;

        if (statusType === PAYMENT_STATUS_TYPES.STEP_UP) return;
      }

      setIsPaymentDisabled(false);

      if (error) {
        setErrorMessages(error);
        setShouldShowLoader(false);
      }

      if (statusType === PAYMENT_STATUS_TYPES.SUCCESS) {
        dispatch(
          actionCreateReservationRequest(
            createReservationPayload({
              ...draftReservation,
              ...paymentNonceDetails,
              paymentInstrumentToken: intentId,
              paymentGratuity: toCents(paymentGratuity),
              paymentServiceCharge: toCents(paymentServiceCharge),
              paymentTax: toCents(paymentTax),
              paymentSubTotal: toCents(paymentSubTotal),
              paymentPolicy: venuePaymentPolicy,
              paymentRule,
              paymentAccountReferenceId:
                paymentAccount?.associatedAccountReferenceId,
            })
          )
        );
      }
    } else if (paymentRule === PAYMENT_RULE_TYPE.SAVE_FOR_LATER) {
      clearErrorMessages();
      setIsPaymentDisabled(true);

      let error = null;
      let statusType = null;
      let intentId = null;
      let paymentNonceDetails = {};

      if (shouldShowStripeForm) {
        const response = await handleSetupIntentStripe(
          stripe,
          elements,
          draftReservation.venue.sevenRoomsVenueId || venue.sevenRoomsVenueId,
          name
        );
        error = response.error;
        statusType = response.statusType;
        intentId = response.intentId;
      } else {
        const response = await getPaymentNonceDetails();
        error = response.error;
        statusType = response.statusType;
        paymentNonceDetails = response.details;
      }

      setIsPaymentDisabled(false);
      if (error) {
        setErrorMessages(error);
        setShouldShowLoader(false);
      }

      if (statusType === PAYMENT_STATUS_TYPES.SUCCESS) {
        dispatch(
          actionCreateReservationRequest(
            createReservationPayload({
              ...draftReservation,
              ...paymentNonceDetails,
              paymentInstrumentToken: intentId,
              paymentPolicy: venuePaymentPolicy,
              paymentRule,
              paymentAccountReferenceId:
                paymentAccount?.associatedAccountReferenceId,
            })
          )
        );
      }
    } else {
      // NO PAYMENT RULES
      dispatch(
        actionCreateReservationRequest(
          createReservationPayload({
            ...draftReservation,
            paymentRule: PAYMENT_RULE_TYPE.NONE,
          })
        )
      );
    }
  };

  const stripeProperties = {
    handleSetName,
    cardNumberError,
    cardCvcError,
    cardCardExpiryError,
  };

  const reservationChargesProperties = {
    reservationObj,
    totalAmount,
    originalAmount,
    setTotalAmount,
    customTip,
    setCustomTip,
    tipProperties,
    setTipProperties,
    setPaymentGratuity: (gratuityAmount) => {
      setPaymentGratuity(gratuityAmount);
      // Save paymentGratuity on draftReservation so it is persisted on redirect from step up
      dispatch(
        actionSetDraftReservation({
          ...draftReservation,
          paymentGratuity: gratuityAmount,
        })
      );
    },
  };

  return {
    draftReservation,
    handleReserve,
    isPaymentDisabled,
    mounted,
    navigationMenuVisible,
    paymentLoading,
    paymentRule,
    redirectToConfirmPage,
    reservation,
    reservationChargesProperties,
    reservationCreateStatus,
    reservationFetchStatus,
    reservationUpdateStatus,
    shouldShowLoader,
    shouldShowStripeForm,
    showAlert,
    showInfoBlock,
    stripeAlertMessage,
    stripeProperties,
    venuePaymentPolicy,
  };
};
