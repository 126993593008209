import { put, takeLatest } from 'redux-saga/effects';
import { listSeatingOptions } from '../../graphql/customQueries';
import { callGraphqlWithToken, dispatchError } from '../helpers';
import * as ACTIONS from './SeatingOptionsAction';
import * as TYPES from './SeatingOptionsTypes';

export function* seatingOptionListRequestHandler() {
  try {
    const filter = { isActive: { eq: true } };
    const result = yield callGraphqlWithToken({
      query: listSeatingOptions,
      variables: { filter },
    });
    yield put(
      ACTIONS.actionSeatingOptionsListSuccess(
        result.data.listSeatingOptions.items
      )
    );
  } catch (e) {
    console.log(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionSeatingOptionsListFail(e));
  }
}

export default function* seatingOptionsSaga() {
  yield takeLatest(
    TYPES.SEATING_OPTION_LIST_REQUEST,
    seatingOptionListRequestHandler
  );
}
