import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { ReactComponent as GravityCalendar } from '../../../assets/icons/grv-calendar.svg';
import EventCarousel from '../../../components/core/EventCarousel/EventCarousel';
import { useCopy } from '../../../utils/useCopy';
import {
  DINING_EVENTS_TITLE,
  DINING_SECTION_TITLE,
} from '../../../assets/copy';
import { BREAKPOINT_VALUES } from '../../../utils/constants/Breakpoints';
import { useViewport } from '../../../utils/useViewport';
import { CtaLink } from '../../../components/spoon/Button';
import PageSection from '../../../components/core/PageSection';
import SectionTitle from '../../../components/spoon/SectionTitle';
import Typography from '../../../components/overrides/Typography';
import { trackClick } from '../../../utils/useOneTag';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(3),
  },
  carouselContainer: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(4.25),
    },
  },
  textStyleOverride: {
    fontWeight: '300 !important',
  },
  pageSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.75),
    padding: theme.spacing(1, 3),
    boxSizing: 'border-box',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 5),
    },
    [theme.breakpoints.up('xxl')]: {
      padding: theme.spacing(1, 0),
    },
  },
  sectionTitleWrapper: {
    marginTop: theme.spacing(5),
    paddingBottom: theme.spacing(1.5),
    [theme.breakpoints.up('xxl')]: {
      paddingBottom: theme.spacing(1),
    },
  },
  icon: {
    width: 16,
    height: 16,
  },
  ctaLink: {
    [theme.breakpoints.up('xl')]: {
      fontSize: '20px',
    },
  },
}));

export const Events = ({ events = [] }) => {
  const classes = useStyles();
  const { width } = useViewport();
  const isMobile = width < BREAKPOINT_VALUES.xl;
  const isDesktop = width > BREAKPOINT_VALUES.xxl;
  const { getCopy } = useCopy();
  const history = useHistory();
  const lengthOfEvents = events?.length;

  const ctaOnClickHandler = (event) => {
    trackClick(
      event,
      'event-carousel-see-all-upcoming-events-button',
      'Button'
    );
    history.push('/dining-experience');
  };

  return (
    <Box className={classes.container}>
      <PageSection className={classes.pageSection}>
        <div className={classes.sectionTitleWrapper}>
          <SectionTitle
            text={DINING_EVENTS_TITLE}
            icon={<GravityCalendar className={classes.icon} />}
            color="dark"
            size={isDesktop ? 'large' : 'normal'}
          />
        </div>
        <Typography
          variant={isMobile ? 'medium2Light' : 'large1Light'}
          className={classes.description}
          component="h2">
          {DINING_SECTION_TITLE}
        </Typography>
      </PageSection>

      <Box className={classes.carouselContainer}>
        <EventCarousel slides={events} />
      </Box>
      <PageSection className={classes.pageSection}>
        {lengthOfEvents > 0 && (
          <CtaLink
            onClick={ctaOnClickHandler}
            size={isMobile ? 'normal' : 'medium'}>
            {getCopy('SEE_ALL_UPCOMING_EVENTS_LINK_TEXT')}
          </CtaLink>
        )}
      </PageSection>
    </Box>
  );
};

export default Events;
