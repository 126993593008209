import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Typography from '../overrides/Typography';
import Button from '../overrides/Button';

const FilledButton = withStyles((theme) => ({
  root: {
    borderRadius: 4,
    height: 48,
    width: '100%',
    '& .MuiTouchRipple-child': {
      backgroundColor: theme.palette.spoon.black[100],
    },
    '& .MuiButton-label': {
      'zIndex': 10,
      position: 'relative',
    }
  },
}))(Button);

function CButton({
  text,
  onClick,
  color = 'primary',
  type = 'button',
  style,
  variant = 'medium2Normal',
  disabled = false,
  ...otherProps
}) {
  return (
    <FilledButton
      type={type}
      variant="contained"
      color={color}
      aria-label={text}
      onClick={onClick}
      className={style}
      disabled={disabled}
      {...otherProps}>
      <Typography variant={variant} color="secondary">
        {text}
      </Typography>
    </FilledButton>
  );
}

CButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CButton;
