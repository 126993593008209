import React, { useEffect } from 'react';
import { List, Box, CircularProgress, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import OnboardingWelcomeBg from '../../assets/images/seville-day3-179.jpeg';
import WelcomeItem from '../../components/onboarding/WelcomeItem';
import BulletOneSvg from '../../assets/icons/dining-blue.svg';
import BulletTwoSvg from '../../assets/icons/tickets-blue.svg';
import BulletThreeSvg from '../../assets/icons/ribbon-blue.svg';
import Typography from '../../components/overrides/Typography';
import {
  PROGRESS_BAR_LABEL,
  LOADING_TEXT,
  ONBOARDING_LITE_WELCOME_TEXT,
  ONBOARDING_LITE_BULLET_ONE_TEXT,
  ONBOARDING_LITE_BULLET_TWO_TEXT,
  ONBOARDING_LITE_BULLET_THREE_TEXT,
  ONBOARDING_LITE_LOADING_TEXT,
} from '../../assets/copy';
import { Layout } from './Layout';
import { trackPageView } from '../../utils/useOneTag';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  welcomeItemText: {
    color: theme.palette.beacon.midnight,
    fontSize: '14px',
    lineHeight: '20px',
    [theme.breakpoints.up('xl')]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  welcomeItemIcon: {
    '& path': {
      fill: theme.palette.beacon.midnight,
    },
  },
  title: {
    color: theme.palette.beacon.midnight,
    lineHeight: '40px',
    marginBottom: theme.spacing(2),
  },
  scrollable: {
    overflowY: 'auto',
  },
  divider: {
    '&.MuiDivider-root': {
      backgroundColor: theme.palette.beacon.heritageRed,
      [theme.breakpoints.up('xl')]: {
        backgroundColor: theme.palette.beacon.brick,
      },
    },
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up('xl')]: {
      marginRight: theme.spacing(8),
    },
  },
  loadingText: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(5),
      fontSize: '20px',
      lineHeight: '30px',
    },
  },
  circle: {
    color: theme.palette.beacon.brick,
    marginBottom: theme.spacing(2),
  },
}));

const Welcome = ({ handleNext }) => {
  const classes = useStyles();
  const contents = [
    {
      icon: BulletOneSvg,
      content: ONBOARDING_LITE_BULLET_ONE_TEXT,
    },
    {
      icon: BulletTwoSvg,
      content: ONBOARDING_LITE_BULLET_TWO_TEXT,
    },
    {
      icon: BulletThreeSvg,
      content: ONBOARDING_LITE_BULLET_THREE_TEXT,
    },
  ];
  
  useEffect(() => {
    trackPageView('/onboarding__welcome');
  }, []);

  useEffect(() => {
    // Show onboarding loader for 7 seconds before showing FTUX
    setTimeout(() => {
      handleNext();
    }, 7000);
  }, [handleNext]);

  return (
    <Layout backgroundImage={OnboardingWelcomeBg}>
      <Box tabIndex={0} className={classes.wrapper}>
        <Typography
          className={classes.title}
          component="h1"
          variant="large1LightFlex">
          {ONBOARDING_LITE_WELCOME_TEXT}
        </Typography>
        <Box className={classes.scrollable}>
          <List className={classes.list}>
            {contents.map((item, index) => (
              <WelcomeItem
                item={item}
                key={index}
                classesOverride={{
                  content: classes.welcomeItemText,
                  avatar: classes.welcomeItemIcon,
                }}
              />
            ))}
          </List>
          <Divider className={classes.divider} />
          <Typography
            className={classes.loadingText}
            component="p"
            variant="medium1Normal">
            {ONBOARDING_LITE_LOADING_TEXT}
          </Typography>
          <CircularProgress
            size={48}
            className={classes.circle}
            role="progressbar"
            aria-label={PROGRESS_BAR_LABEL}
            title={LOADING_TEXT}
            aria-valuenow={0}
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default Welcome;
