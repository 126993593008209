export const combine7RVenueIdAndExperienceId = (venue) => {
  return venue?.sevenRoomsVenueId + (venue?.experienceId ?? '');
};

export const filterTimeslots = (timeslots, timeSlot, slotOptionId) => {
  return timeslots?.find(
      (timeslot) =>
          timeslot.timeSlot === timeSlot && timeslot.slotOptionId === slotOptionId
  );
}
