import React, { useEffect } from 'react';

import {
  Box,
  Card,
  CardMedia,
  CardContent,
  makeStyles,
} from '@material-ui/core';
import Typography from '../../overrides/Typography';
import { ellipsify } from '../../../utils/stringHelperMethods';
import Badge from '../Badge';
import OutlineButton from '../../core/OutlineButton';
import {
  displayCardEventDates,
  displayCardEventTimes,
  displayLocationText,
  joinText,
} from '../../../utils/eventHelpers';
import { CARDHOLDER_ONLY_TICKETS_TEXT } from '../../../assets/copy';
import { useHistory } from 'react-router';
import {
  trackClickWithDescription,
  trackPageImpression,
} from '../../../utils/useOneTag';
import { removeHtmlTags } from '../../../utils/stringHelperMethods';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '1200px',
    borderRadius: 6,
    backgroundColor: theme.palette.blue[70],
    display: 'flex',
    height: 440,
    cursor: 'pointer',
    maxWidth: '99vw',
  },
  content: {
    minWidth: 459,
    boxSizing: 'border-box',
    backgroundColor: theme.palette.blue[70],
    color: theme.palette.text.secondary,
    padding: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
  },
  eventDateText: {
    letterSpacing: 0.5,
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  eventNameText: {
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontWeight: 300,
    fontSize: 32,
    lineHeight: '36px',
    padding: theme.spacing(2, 0),
    fontStyle: 'normal',
    color: theme.palette.text.secondary,
  },
  cardContainer: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 !important',
  },
  informationContainer: {
    justifyContent: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    paddingTop: theme.spacing(2),
  },
  outlineButton: {
    backgroundColor: theme.palette.secondary.contrastText,
    color: '#121212',
    height: 56,
    width: 222,
    '&:hover': {
      backgroundColor: theme.palette.secondary.contrastText,
    },
  },
  description: {
    fontSize: 15,
  },
  eventDescriptionText: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    color: theme.palette.text.secondary,
  },
}));

const EventHorizontalCard = ({ item }) => {
  const classes = useStyles();
  const relatedVenues = item?.relatedVenues?.items;
  const locationText = displayLocationText(item, relatedVenues);
  const history = useHistory();
  const redirectToVenueDetailsPage = () => {
    trackClickWithDescription('event-horizontal-card', 'Button', item?.name);
    history.push(`/venue/${item?.id}`);
  };
  useEffect(() => {
    if (item.name) {
      trackPageImpression('events', 'event-horizontal-card', item.name);
    }
  }, [item?.name]);

  return (
    <Card className={classes.root} onClick={redirectToVenueDetailsPage}>
      <Box className={classes.content}>
        <CardContent component="div" className={classes.cardContainer}>
          <Box overflow="hidden" borderRadius={4}>
            {locationText && <Badge text={locationText} type="eventCard" />}
          </Box>
          <Box className={classes.informationContainer} flexGrow={1}>
            <Box component="div" minHeight={18}>
              <Typography
                className={classes.eventDateText}
                variant="medium1Normal">
                {joinText(
                  [
                    displayCardEventDates(item.eventDates),
                    displayCardEventTimes(item.eventTimes),
                  ],
                  ' | '
                )}
              </Typography>
            </Box>
            <Box component="div" minHeight={60}>
              <Typography className={classes.eventNameText}>
                {ellipsify(item.name, 36)}
              </Typography>
            </Box>
            <Box component="div" minHeight={36}>
              <Typography
                className={classes.eventDescriptionText}
                variant="medium3Normal">
                {removeHtmlTags(
                  item.description.replaceAll(/((<br>)|(<br\/>))/g, ' ')
                )}
              </Typography>
            </Box>
            <Box
              component="div"
              className={classes.buttonContainer}
              minHeight={36}>
              <OutlineButton
                tabIndex="0"
                text={CARDHOLDER_ONLY_TICKETS_TEXT}
                style={classes.outlineButton}
                variant="medium1Semibold"
                onKeyPress={redirectToVenueDetailsPage}></OutlineButton>
            </Box>
          </Box>
        </CardContent>
      </Box>
      <Box style={{ height: '100%', width: '100%' }}>
        <CardMedia
          component="img"
          width="100%"
          image={item?.imagesFull[0]}
          style={{ height: '100%', minWidth: '821px' }}
          alt=""
        />
      </Box>
    </Card>
  );
};

export default EventHorizontalCard;
