import React, { useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Typography from '../../overrides/Typography';
import {
  BOOK_A_TABLE_TEXT,
  GUEST_SELECTOR_DROPDOWN_LABEL,
} from '../../../assets/copy';
import { GuestSelector } from '../Select';
import AvailabilityCalendar from '../AvailabilityCalendar';
import { useDispatch, useSelector } from 'react-redux';
import { generateGetDateRangeAvailabilityInput } from '../../../utils/generateAvailabilityInput';
import { getDaysInMonthFromDate } from '../../../utils/dayMonthHelper';
import {
  actionClearDateRangeAvailabilities,
  actionClearListAvailabilities,
  actionGetDateRangeAvailabilityRequest,
  actionPreloadDateRangeAvailabilityRequest,
  actionSetCalendarDisplayedMonth,
  actionSetDateRangeAvailabilityTimeslotList,
  actionSetFabAvailabilityGuests,
  actionSetFabDisplayValues,
} from '../../../store/Availability/AvailabilityAction';
import { DateTime } from 'luxon';
import {
  YEAR_MONTH_DATE_FORMAT,
  YEAR_MONTH_FORMAT,
} from '../../../assets/dateFormats';
import {
  trackClickWithDescription,
  trackPageImpression,
} from '../../../utils/useOneTag';

const useStyles = makeStyles((theme) => ({
  calendarContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  guestSelectorRoot: {
    maxWidth: 350,
  },
}));

/**
 * CalendarSelector component.
 * Initial availability call is expected to be triggered outside this component.
 * This component will populate the availabilityStore.list variable which can be used to display timeslots.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.venue - The venue object.
 * @param {string} props.guests - The number of guests.
 * @param {boolean} props.loadingAvailability - Flag indicating if availability is loading.
 * @param {boolean} [props.showBookTableText=false] - Flag to show the "Book a Table" text.
 * @param {boolean} [props.showCalendarShadow=true] - Flag to show shadow on the calendar.
 * @returns {JSX.Element} The CalendarSelector component.
 */
export const CalendarSelector = ({
  venue,
  guests,
  loadingAvailability,
  showBookTableText = false,
  showCalendarShadow = true,
  dateSelectionCTA = BOOK_A_TABLE_TEXT,
  ticketSelectionLabel = GUEST_SELECTOR_DROPDOWN_LABEL,
  classes: classesOverride,
}) => {
  const classes = useStyles({ classes: classesOverride });

  const dispatch = useDispatch();
  const availabilityStore = useSelector((state) => state.availability);

  const [selectedGuests, setSelectedGuests] = useState(guests);
  const [selectedDate, setSelectedDate] = useState(
    availabilityStore.calendarSelectedDate
      ? new Date(availabilityStore.calendarSelectedDate)
      : new Date()
  );

  const currentDate = new Date();
  const calendarKey = useRef(currentDate);

  // used to determine the spinner on the calendar
  const loadingCalendar = useMemo(() => {
    return !availabilityStore.availableDatesList || loadingAvailability;
  }, [availabilityStore.availableDatesList, loadingAvailability]);

  // used to update the selectedDate to the earliest availability on initial load.
  useEffect(() => {
    if (
      availabilityStore.initialCalendarSelectedDate && // ignores null value
      (!availabilityStore.calendarSelectedDate || // necessary for when initial loading hits no availability and cta needs to change month view.
        availabilityStore.calendarSelectedDate ===
          availabilityStore.initialCalendarSelectedDate || // necessary for setting an initial selected date when first loading or refreshing guest count.
        availabilityStore.list?.[0].timeslots?.length === 0) // necessary for when need to change month view for CTA but still maintaining the selected date state.
    ) {
      const newDate = new Date(availabilityStore.initialCalendarSelectedDate);
      setSelectedDate(newDate);
      calendarKey.current = newDate.toISOString();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilityStore.initialCalendarSelectedDate]);

  useEffect(() => {
    // if the selectedGuests changes, then we need to fetch the availability for the new guest count.
    // only fetch if the guest count changed
    if (selectedGuests !== availabilityStore.submittedGuests) {
      const input = generateGetDateRangeAvailabilityInput(
        venue,
        availabilityStore.calendarVisibleMonth,
        selectedGuests,
        getDaysInMonthFromDate(availabilityStore.calendarVisibleMonth) +
          getDaysInMonthFromDate(availabilityStore.calendarVisibleMonth, 1)
      );
      dispatch(actionClearListAvailabilities());
      dispatch(actionClearDateRangeAvailabilities());
      dispatch(actionGetDateRangeAvailabilityRequest(input));
      dispatch(
        actionSetFabAvailabilityGuests({
          selectedGuests: selectedGuests,
        })
      );
      dispatch(
        actionSetFabDisplayValues({
          submittedGuests: selectedGuests,
          submittedTickets: selectedGuests,
        })
      );
    }
    // only want to trigger the effect when the selectedGuests changes and none of the other variables.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGuests]);

  useEffect(() => {
    // only set the timeslot list if we already loaded availability
    if (!loadingAvailability) {
      dispatch(actionSetDateRangeAvailabilityTimeslotList(selectedDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const handleSelect = (e) => {
    trackClickWithDescription(
      'calendar-guest-selector',
      'Button',
      e.target.value
    );
    if (e.target.value !== selectedGuests) {
      setSelectedGuests(e.target.value);
    }
  };

  const handleDateChange = (newDate) => {
    trackClickWithDescription(
      'calendar-date-selector',
      'Button',
      DateTime.fromJSDate(newDate).toFormat(YEAR_MONTH_DATE_FORMAT)
    );
    if (newDate !== selectedDate) {
      setSelectedDate(newDate);
    }
  };

  const handleMonthChange = (startDate, endDate) => {
    // used when the calendar UI changes the displayed month.
    const formattedEndDate = DateTime.fromJSDate(endDate).toFormat(
      YEAR_MONTH_DATE_FORMAT
    );

    if (
      availabilityStore.list?.[0].timeslots?.length === 0 &&
      availabilityStore.availableDatesList?.length > 0
    ) {
      // if there are no timeslots available then we should check if new month have availability.
      const filteredDates = availabilityStore.availableDatesList
        .filter((date) => date >= startDate && date <= endDate)
        .sort((a, b) => a - b);

      if (filteredDates.length > 0) {
        setSelectedDate(filteredDates[0]);
        dispatch(actionSetDateRangeAvailabilityTimeslotList(filteredDates[0]));
      }
    }

    if (
      availabilityStore.calendarVisibleMonth &&
      !availabilityStore.dateRangeAvailability?.some(
        (availability) => availability.date === formattedEndDate
      )
    ) {
      // if the current displayed month does not have availability fetched then fetch it.
      const queryDate = new Date(startDate);
      const input = generateGetDateRangeAvailabilityInput(
        venue,
        queryDate,
        guests,
        getDaysInMonthFromDate(queryDate)
      );
      dispatch(actionGetDateRangeAvailabilityRequest(input));
    }

    const oneMonthAfterStartDate = DateTime.fromJSDate(startDate)
      .plus({ months: 1 })
      .toFormat(YEAR_MONTH_DATE_FORMAT);
    if (
      availabilityStore.calendarVisibleMonth &&
      !availabilityStore.dateRangeAvailability?.some(
        (availability) => availability.date === oneMonthAfterStartDate
      )
    ) {
      // if the next month from displayed month does not have availability fetched then fetch it.
      const queryDate = DateTime.fromFormat(
        oneMonthAfterStartDate,
        YEAR_MONTH_DATE_FORMAT
      ).toJSDate();
      const input = generateGetDateRangeAvailabilityInput(
        venue,
        queryDate,
        guests,
        getDaysInMonthFromDate(queryDate)
      );
      dispatch(actionPreloadDateRangeAvailabilityRequest(input));
    }

    if (
      availabilityStore.calendarVisibleMonth?.getMonth() !==
      startDate.getMonth()
    ) {
      trackPageImpression(
        'calendar',
        'calendar-selector',
        DateTime.fromJSDate(endDate).toFormat(YEAR_MONTH_FORMAT)
      );
      dispatch(actionSetCalendarDisplayedMonth(startDate));
    }
  };

  return (
    <div className={classes.calendarContainer}>
      {showBookTableText && (
        <Typography variant="medium1Semibold" component="h2">
          {dateSelectionCTA}
        </Typography>
      )}
      <GuestSelector
        minGuests={1}
        maxGuests={10}
        value={selectedGuests}
        dropdownLabel={ticketSelectionLabel}
        onChange={handleSelect}
        classes={{ root: classes.guestSelectorRoot }}
      />
      <AvailabilityCalendar
        key={calendarKey.current} // allows calendar to change months if the earliest availability is in another month.
        hasShadow={showCalendarShadow}
        showTodayLabel={true}
        date={selectedDate}
        minDate={currentDate}
        isLoading={loadingCalendar}
        availableDates={availabilityStore.availableDatesList}
        onDateSelected={handleDateChange}
        onVisibleDateChange={handleMonthChange}
      />
    </div>
  );
};

CalendarSelector.propTypes = {
  venue: PropTypes.object.isRequired,
  guests: PropTypes.string.isRequired,
  loadingAvailability: PropTypes.bool.isRequired,
  showBookTableText: PropTypes.bool,
  showCalendarShadow: PropTypes.bool,
  classes: PropTypes.object,
};

export default CalendarSelector;
