import { put, takeLatest } from 'redux-saga/effects';

import { listAwards } from '../../graphql/customQueries';
import { callGraphqlWithToken, dispatchError } from '../helpers';
import * as ACTIONS from './AwardsAction';
import * as TYPES from './AwardsTypes';

export function* awardListRequestHandler() {
  try {
    const filter = { isActive: { eq: true }, status: { eq: true } };
    const result = yield callGraphqlWithToken({
      query: listAwards,
      variables: { filter },
    });
    yield put(ACTIONS.actionAwardListSuccess(result.data.listAwards.items));
  } catch (e) {
    console.log(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionAwardListFail(e));
  }
}

export default function* awardsSaga() {
  yield takeLatest(TYPES.AWARD_LIST_REQUEST, awardListRequestHandler);
}
