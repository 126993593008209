import React from 'react';
import PropTypes from 'prop-types';
import { Container, makeStyles } from '@material-ui/core';
import { SIGNATURE_COLLECTION_TIMESLOT_TEXT } from '../../../assets/copy';
import Typography from '../../overrides/Typography';
import { TimeButton, TimeButtonGroup } from '../../core/TimesButton';
import { ReactComponent as BenefitsIcon } from '../../../assets/icons/grv-benefits.svg';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import CalendarSelector from '../../core/CalendarSelector';
import NoCalendarAvailabilityInfo from '../../../pages/venueAvailability/NoCalendarAvailabilityInfo';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    overflowX: 'hidden',
  },
  exclusiveExplainerContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    margin: theme.spacing(2.5, 0, 3),
    '& > *': {
      fontSize: 12,
    },
  },
  timeButtonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    overflowX: 'auto',
  },
  dateHeading: {
    textAlign: 'left',
    width: '100%',
    margin: theme.spacing(1.5, 0),
  },
  benefitsIcon: {
    width: 28,
    height: 28,
    flexShrink: 0,
    '& path': {
      fill: `${theme.palette.spoon.yellow[50]} !important`,
    },
  },
}));

export const ChooseDate = ({ venue, onTimeButtonClick }) => {
  const {
    list: availabilities,
    loadingAvailability,
    submittedGuests,
    calendarVisibleMonth,
  } = useSelector((state) => state.availability);

  const classes = useStyles();
  return (
    <>
      <Container className={classes.container}>
        <CalendarSelector
          venue={venue}
          guests={submittedGuests}
          loadingAvailability={loadingAvailability}
          showCalendarShadow={false}
        />
        {availabilities?.[0].timeslots?.length > 0 && (
          <Typography
            variant="medium1Semibold"
            className={classes.dateHeading}
            component="h3">
            {DateTime.fromISO(availabilities[0].date).toLocaleString({
              weekday: 'long',
              month: 'long',
              day: 'numeric',
            })}
          </Typography>
        )}
      </Container>
      <div aria-label={SIGNATURE_COLLECTION_TIMESLOT_TEXT}>
        <TimeButtonGroup bufferWidth={'16px'}>
          {availabilities?.[0].timeslots?.map((timeslot, i) => (
            <TimeButton
              key={`timeslot_${i}`}
              onClick={() =>
                onTimeButtonClick(
                  availabilities[0].date,
                  timeslot,
                  parseInt(submittedGuests)
                )
              }
              venueParent={{ isInsider: timeslot.isInsider }}
              timeslot={timeslot}
              text={timeslot.timeSlot}
            />
          ))}
        </TimeButtonGroup>
      </div>
      {availabilities?.[0].timeslots?.length === 0 && (
        <Container>
          <NoCalendarAvailabilityInfo
            date={calendarVisibleMonth}
            useDivider={false}
          />
        </Container>
      )}
      <Container className={classes.exclusiveExplainerContainer}>
        {venue?.isSignatureCollection && (
          <>
            <BenefitsIcon className={classes.benefitsIcon} role="img" aria-labelledby="exclusiveCardholderTableIcon">
              <p id="exclusiveCardholderTableIcon"></p>
            </BenefitsIcon>
            <Typography variant="small1Normal" id="exclusiveCardholderTableIcon">
              {SIGNATURE_COLLECTION_TIMESLOT_TEXT}
            </Typography>
          </>
        )}
      </Container>
    </>
  );
};

ChooseDate.propTypes = {
  venue: PropTypes.shape({
    sevenRoomsVenueId: PropTypes.string.isRequired,
    experienceId: PropTypes.string,
  }).isRequired,
  onTimeButtonClick: PropTypes.func.isRequired,
};
