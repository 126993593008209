import { darken, lighten } from '@material-ui/core/styles';

const palette = {
  primary: {
    main: '#0276B1',
    100: '#0276B1',
    50: '#0276B1',
    60: '#10253F',
    70: '#014E74',
    10: '#606A77',
    light: lighten('#0276B1', 0.3),
    dark: '#013D58', // coreBlue
    text: '#141414',
  },
  secondary: {
    // digitalGrey
    main: '#141414',
    100: '#141414',
    80: '#3D3D3D',
    70: '#525252',
    65: '#6E6E6E',
    60: '#D4D4D4',
    50: '#7D7D7D',
    40: '#949494',
    contrastText: '#FFFFFF',
    light: lighten('#EFEFEF', 0.3),
    dark: darken('#FEFEFE', 0.3),
  },
  text: {
    primary: '#141414',
    secondary: '#FFFFFF',
  },
  input: {
    border: '#ABABAB',
  },
  background: {
    white: '#FFFFFF',
    default: '#282c34',
    light: '#faefeb',
    gray: '#A8A8A8',
    grayLight: '#F4F4F4',
    blue: '#10253f',
  },
  chip: {
    background: '#E1F5FF',
    active: '#026597',
    default: '#0073AD',
    event: '#D9FAD1',
    soldout: '#E6E6E6',
  },
  error: {
    main: '#CD2328',
    dark: darken('#CD2328', 0.3),
    contrastText: '#FFFFFF',
  },
  interactionBlue: {
    main: '#0276B1',
    10: '#E1F5FF',
    30: '#3ABCFD',
    40: '#028ED4',
    50: '#0276B1',
    60: '#026597',
    70: '#014E74',
  },
  interactionRed: {
    main: '#CC2427',
    30: '#FB5053',
    50: '#CC2427',
    60: '#BA2629',
    70: '#A3282B',
  },
  blue: {
    40: '#255F82',
    50: '#013D58',
    70: '#00132B',
  },
  digitalGray: {
    10: '#F4F4F4',
    20: '#D4D4D4',
    30: '#E4E5E5',
    60: '#696969',
    80: '#7D7D7D',
    90: '#696969',
    95: '#1F1F1F',
    100: '#525252',
    110: '#3D3D3D',
    120: '#141414',
  },
  yellow: {
    40: '#FBD44D',
  },
  gray: {
    5: '#F4F4F4',
    15: '#D4D4D4',
    30: '#E4E5E5',
    40: '#949494',
    50: '#525252',
    100: '#EEEEEE',
  },
  red: {
    light: '#FFE5E6',
    text: '#BA2629',
    50: '#CC2427',
  },
  charcoal: {
    40: '#3E4B59',
    60: '#0E1D2B',
  },
  green: {
    70: '#008140',
  },
  spoon: {
    blue: {
      70: '#00132B',
      50: '#013D5B',
      40: '#0070A8',
      20: '#BCE9FF',
    },
    white: {
      100: '#FFFFFF',
    },
    gray: {
      100: '#3D3D3D',
      10: '#E9E9E9',
      5: lighten('#3D3D3D', 0.95),
    },
    black: {
      100: '#000000',
      90: '#121212',
    },
    red: {
      50: '#CC2427',
    },
    yellow: {
      50: '#F9C606',
      10: '#FFF1D0',
      warning: '#8F6400',
    },
  },
  beacon: {
    // red
    brick: '#BF5547',
    heritageRed: '#CC2427',
    // blue
    atlantic: '#013D5B',
    midnight: '#00132B',
    surf: '#D9EBED',
  },
};

export default palette;
