import React from 'react';
import { ButtonBase, makeStyles } from '@material-ui/core';
import { ReactComponent as LeftSvg } from '../../assets/icons/leftArrowCarousel.svg';
import { ReactComponent as RightSvg } from '../../assets/icons/rightArrowCarousel.svg';

const useStyles = makeStyles(() => ({
  root: {
    height: 50,
    width: 50,
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
}));

const ChevronButton = ({ direction = 'left', onClickHandler }) => {
  const classes = useStyles();
  const isLeft = direction === 'left';
  return (
    <ButtonBase
      focusRipple
      tabIndex="0"
      onClick={onClickHandler}
      className={classes.root}
      aria-label={isLeft?"Previous":"Next"}>
      {isLeft ? <LeftSvg role="img" aria-hidden="true" /> : <RightSvg role="img" aria-hidden="true" />}
    </ButtonBase>
  );
};

export default ChevronButton;
