/**
 * NOTE: Whenever a new route is added here, we also need to update allow list in the CloudFront
 * default viewer request function in https://github.cloud.capitalone.com/cardrewards-diningexperience/DiningPortalCloudFront
 * so that the viewer is allowed to see the route; otherwise they will see the static 404 error page.
 */

import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useIdleTimeout } from './utils/useIdleTimeout';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { useFeatureFlags } from './utils/useFeatureFlags';

import UserDropdown from './pages/users';
import ErrorBoundary from './components/core/ErrorBoundary';
import PageLayout from './components/core/PageLayout';
import PrivateRoute from './components/core/PrivateRoute';
import AppWrapper from './components/core/AppWrapper';
import AppBar from './components/core/AppBar';
import Footer from './components/core/Footer/';
import SearchResultWrapper from './components/search/SearchResultWrapper';
import Onboarding from './pages/onboarding';
import OnboardingRefresh from './pages/onboarding-refresh';
import Home from './pages/home';
import Profile from './pages/profile';
import Venue from './pages/venue';
import VenueAvailability from './pages/venueAvailability';
import ReservationsList from './pages/reservations';
import Reserve from './pages/reserve';
import ReservationDetails from './pages/reserve/ReservationDetails';
import ReservationEdit from './pages/reserve/ReservationEdit';
import ReservationCancel from './pages/reserve/ReservationCancel';
import ReservationConfirm from './pages/reserve/ReservationConfirm';
import ReservationAvailability from './pages/reserve/ReservationAvailability';
import ReservePermalink from './pages/reserve/ReservePermalink';
import EditContact from './pages/reserve/EditContact';
import EditDietPref from './pages/reserve/EditDietPref';
import EditNote from './pages/reserve/EditNote';
import AnimatedSwitch from './components/animation/AnimatedSwitch';
import PremierLocation from './pages/premierLocation';
import Search from './pages/search';
import DiningExperience from './pages/diningExperience';
import { useAuth } from './utils/useAuth';
import Signout from './pages/session/Signout';
import DefaultError from './pages/error/DefaultError';
import TimerDialog from './components/core/Dialog/TimerDialog';
import PermaLink from './pages/permalink';
import TrackedRoute from './components/core/TrackedRoute';
import HomeRefresh from './pages/home-refresh';

function AppRouter() {
  const location = useLocation();
  const history = useHistory();
  const {
    redirectToC1Consent,
    signout,
    searchParams: { assertion, code, state: oauthState },
    user,
  } = useAuth();
  const { errorType } = useSelector((state) => state.error);
  const { isReservationModalOpen } = useSelector((state) => state.reservations);
  const { refreshLandingPageFlagIsEnabled, refreshFtuxLiteFlagIsEnabled } =
    useFeatureFlags();

  useEffect(() => {
    if (errorType != null && !isReservationModalOpen) {
      history.push('/error');
    }
  }, [history, errorType, isReservationModalOpen]);

  const [isOpen, setIsOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inactiveUserLogout = () => {
    if (document.visibilityState === 'visible') {
      const exp = getExpirationTimestamp();
      console.log('now:', new Date(), 'to expire', new Date(exp));
      if (exp != null && exp < Date.now()) {
        signout();
        history.push('/sign-out');
      }
    }
  };

  useEffect(() => {
    if (isMounted) {
      window.addEventListener('visibilitychange', inactiveUserLogout);
    }
    return () =>
      window.removeEventListener('visibilitychange', inactiveUserLogout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  const handleOnIdle = () => {
    console.log(`${new Date()} user is idle`);
    setIsOpen(true);
  };

  const handleOnExpire = () => {
    console.log(`${new Date()} user session expired`);
    console.log('expirationTimestamp', getExpirationTimestamp());
    signout();
    if (
      location.pathname === '/' &&
      assertion == null &&
      code == null &&
      oauthState == null
    ) {
      // Redirect only if navigating to home page and not coming from deep link or OAuth flow
      redirectToC1Consent();
    }
  };

  const { getExpirationTimestamp } = useIdleTimeout({
    onTimeout: handleOnIdle,
    onExpired: handleOnExpire,
    timeout:
      Number(process.env.REACT_APP_IDLE_TIMEOUT_MINUTES || 44) * 60 * 1000,
  });

  return (
    <AppWrapper>
      <TimerDialog open={isOpen} setIsOpen={setIsOpen} />
      <AppBar />
      <PageLayout>
        <Switch>
          <PrivateRoute path="/search" component={Search} tracked />
          <PrivateRoute
            exact
            path="/reservations/:id"
            component={ReservationDetails}
            tracked
          />
          <TrackedRoute
              path="/reservations"
              render={(routeProps) => (
                  <ErrorBoundary {...routeProps} user={user}>
                    <ReservationsList {...routeProps} />
                  </ErrorBoundary>
              )}
          />
          <TrackedRoute
            path="/profile"
            render={(routeProps) => (
              <ErrorBoundary {...routeProps} user={user}>
                <Profile {...routeProps} />
              </ErrorBoundary>
            )}
          />
          <Route path="*">
            <AnimatedSwitch>
              <Route exact path="/users" component={UserDropdown} />

              <TrackedRoute
                exact
                path="/"
                render={(routeProps) => (
                  <ErrorBoundary {...routeProps} user={user}>
                    {refreshLandingPageFlagIsEnabled ? (
                      <HomeRefresh {...routeProps} />
                    ) : (
                      <Home {...routeProps} />
                    )}
                  </ErrorBoundary>
                )}
              />
              {refreshFtuxLiteFlagIsEnabled ? (
                <Route
                  exact
                  path="/onboarding"
                  render={(routeProps) => (
                    <ErrorBoundary>
                      <OnboardingRefresh {...routeProps} />
                    </ErrorBoundary>
                  )}
                />
              ) : (
                <TrackedRoute
                  exact
                  path="/onboarding"
                  render={(routeProps) => (
                    <ErrorBoundary>
                      <Onboarding {...routeProps} />
                    </ErrorBoundary>
                  )}
                />
              )}
              <TrackedRoute
                exact
                path="/premier-location"
                render={(routeProps) => (
                  <ErrorBoundary {...routeProps} user={user}>
                    <PremierLocation {...routeProps} />
                  </ErrorBoundary>
                )}
              />
              <TrackedRoute
                exact
                path="/sign-out"
                render={(routeProps) => (
                  <ErrorBoundary {...routeProps} user={user}>
                    <Signout {...routeProps} />
                  </ErrorBoundary>
                )}
              />
              <Route exact path="/error" component={DefaultError} />
              <TrackedRoute
                exact
                path="/dining-experience"
                render={(routeProps) => (
                  <ErrorBoundary {...routeProps} user={user}>
                    <DiningExperience {...routeProps} />
                  </ErrorBoundary>
                )}
              />
              <PrivateRoute
                exact
                path="/venue/:id"
                render={(routeProps) =>
                  routeProps?.location?.state?.isSearchResult ? (
                    <SearchResultWrapper>
                      <Venue {...routeProps} />
                    </SearchResultWrapper>
                  ) : (
                    <Venue {...routeProps} />
                  )
                }
                tracked
              />

              <PrivateRoute
                exact
                path="/venue/:id/check-availability"
                component={VenueAvailability}
                tracked
              />

              <PrivateRoute
                exact
                path="/reserve"
                component={Reserve}
                // not tracked due to custom tracking function on ReservePage
              />
              <PrivateRoute
                exact
                path="/reserve/confirmed"
                component={ReservationConfirm}
                tracked
              />

              {/* Older path second line newer path */}
              <PrivateRoute
                exact
                path="/reserve/edit"
                component={ReservationEdit}
                tracked
              />
              <PrivateRoute
                exact
                path="/reserve/edit/check-availability"
                component={ReservationAvailability}
                tracked
              />
              <PrivateRoute
                exact
                path="/reserve/edit/confirmupdate"
                component={Reserve}
                tracked
              />
              <PrivateRoute
                exact
                path="/reserve/edit/:id"
                component={ReservationEdit}
                tracked
              />

              <PrivateRoute
                exact
                path="/reserve/cancel/confirmed"
                component={ReservationCancel}
                tracked
              />

              <PrivateRoute
                exact
                path="/reserve/contact"
                component={EditContact}
                tracked
              />
              <PrivateRoute
                exact
                path="/reserve/diet"
                component={EditDietPref}
                tracked
              />
              <PrivateRoute
                exact
                path="/reserve/note"
                component={EditNote}
                tracked
              />
              <TrackedRoute
                  exact
                  path="/reserve/create"
                  component={ReservePermalink}
              />
              <TrackedRoute
                  exact
                  path="/permalink/:slug"
                  render={(routeProps) => (
                      <ErrorBoundary {...routeProps} user={user}>
                        <PermaLink {...routeProps} />
                      </ErrorBoundary>
                  )}
              />

              <Route component={DefaultError} />
            </AnimatedSwitch>
          </Route>
        </Switch>
      </PageLayout>
      <Footer />
    </AppWrapper>
  );
}

export default AppRouter;
