import * as React from 'react';
import cn from 'clsx';
import { useMemo } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import gravityPalette from '../global/gravityPalette';
import Typography from '../../overrides/Typography';
import { ChevronLeftRounded, ChevronRightRounded } from '@material-ui/icons';

const useStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 2),
    height: theme.spacing(6),
    borderRadius: 4,
    backgroundColor: ({ variant }) =>
      (gravityPalette.button[variant] || gravityPalette.button.progressive)
        .default,
    color: theme.palette.secondary.contrastText,
    '&:disabled': {
      color: `${gravityPalette.button.disabledText} !important`,
      backgroundColor: gravityPalette.button.disabled,
      cursor: 'not-allowed',
    },
    '&:hover': {
      backgroundColor: ({ variant }) =>
        (gravityPalette.button[variant] || gravityPalette.button.progressive)
          .hover,
      '& p': {
        textDecoration: 'none',
      },
    },
    '& p': {
      textTransform: 'none',
    },
    '& .MuiTouchRipple-child': {
      backgroundColor: theme.palette.spoon.black[100],
    },
    '& .MuiButton-label': {
      'zIndex': 10,
      position: 'relative',
    }
  },
  text: {
    padding: 0,
    '&:disabled': {
      backgroundColor: `transparent !important`,
    },
  },
  compact: {
    height: theme.spacing(4),
  },
  ghost: {
    border: `1px solid ${theme.palette.secondary.contrastText}`,
    '&:hover': {
      '& p': {
        color: `${theme.palette.secondary.main} !important`,
      },
    },
  },
  typography: {
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  directionalButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  variantTextHover: {
    color: ({ customHexColor }) =>
      customHexColor ? customHexColor : theme.palette.primary[50],
    '&:hover': {
      textDecoration: 'underline !important',
      color: ({ customHexColor }) =>
        customHexColor
          ? theme.palette.interactionBlue[40]
          : theme.palette.interactionBlue[60],
    },
  },
  directionalLeftTypo: {
    '&:active': {
      color: theme.palette.interactionBlue[70],
    },
  },
  directionalRightTypo: {
    '&:active': {
      color: theme.palette.interactionBlue[70],
    },
  },
  typoDisabled: {
    color: gravityPalette.button.disabledText,
  },
  link: {
    '& p': {
      display: 'flex',
    },
    '&:hover': {
      textDecoration: 'underline',
    },
    background: 'transparent !important',
  },
  arrow: {
    alignSelf: 'center',
  },
}));

const GravityButton = ({
  variant = 'progressive',
  directional = '',
  disabled = false,
  compact = false,
  children,
  customHexColor,
  width,
  buttonClassName,
  linkTextVariant,
  focusable = true,
  ...other
}) => {
  const classes = useStyle({ variant, customHexColor });
  const typoVariants = useMemo(() => {
    if (linkTextVariant) return linkTextVariant;
    if (directional || compact) return 'medium1Normal';
    return 'medium2Normal';
  }, [linkTextVariant, directional, compact]);

  return (
    <Button
      tabIndex={focusable ? 0 : -1}
      className={cn(classes.root, buttonClassName, classes[variant], {
        [classes.text]:
          variant === 'text' &&
          (directional === 'left' || directional === 'right'),
        [classes.compact]: compact,
      })}
      style={width ? { width } : {}}
      disabled={disabled}
      {...other}>
      <Typography
        className={cn(
          classes.typography,
          classes.directionalButton,
          {
            [classes.variantTextHover]: variant === 'text',
            [classes.directionalLeftTypo]:
              directional === 'left' && variant === 'text',
            [classes.directionalRightTypo]:
              directional === 'right' && variant === 'text',
            [classes.typoDisabled]: disabled,
          },
          buttonClassName
        )}
        variant={typoVariants}>
        {variant === 'text' && directional === 'left' && (
          <ChevronLeftRounded className={classes.arrow} />
        )}
        {children}
        {(variant === 'text' || variant === 'link') &&
          directional === 'right' && (
            <ChevronRightRounded className={classes.arrow} />
          )}
      </Typography>
    </Button>
  );
};

export default GravityButton;
